import mixpanel from "mixpanel-browser";

export const trackEvent = async (eventName, eventPropertyObj) => {
  // mixpanel.track(eventName, eventPropertyObj);
};

export const currentFormattedDateAndTime = () => {
  const currentDate = new Date();

  // Get the hours, minutes, seconds, and milliseconds
  const hours = currentDate.getHours();
  const minutes = currentDate.getMinutes();
  const seconds = currentDate.getSeconds();
  const milliseconds = currentDate.getMilliseconds();

  // Format the time manually to include milliseconds
  const formattedTime = `${hours}:${minutes}:${seconds}.${milliseconds}`;

  return {
    date: currentDate.toLocaleDateString(),  // Date part (MM/DD/YYYY)
    time: formattedTime,                     // Time part (HH:MM:SS.mmm)
  };
};
