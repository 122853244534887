import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Container } from "@mui/material";

const Loader = () => {
  return (
    <Container>
      <div className="flex justify-center">
        <CircularProgress />
      </div>
    </Container>
  );
};
export default Loader;
