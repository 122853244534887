import { useState, useEffect, useContext } from "react";
import "./common.scss";
import FormRendererContext from "../../services/context/FormRendererContext";

const Telephone = ({ data, setAnswer, formValidation = true, formClass }) => {
  const [phoneNumber, setPhoneNumber] = useState(data.answer?.value || "");
  const [validForm, setFormValidation] = useState(formValidation);
  const [isValidNumber, setIsValidNumber] = useState(true);
  const { answerData } = useContext(FormRendererContext);

  useEffect(() => {
    setFormValidation(formValidation);
    if (data?.answer) {
      setPhoneNumber(data.answer?.value);
    } else {
      setPhoneNumber("");
    }
    if (answerData?.[data?.key]) {
      setPhoneNumber(answerData?.[data?.key]?.value);
    }
  }, [data, formValidation]);

  const validatePhoneNumber = (phone) => {
    // US phone number validation regex (supports (xxx) xxx-xxxx or xxx-xxx-xxxx formats)
    const phoneRegex = /^(\(\d{3}\)\s|\d{3}[-]?)\d{3}[-]?\d{4}$/;
    return phoneRegex.test(phone);
  };

  const handlePhoneChange = (e) => {
    const input = e.target.value;
    setPhoneNumber(input);
    const isValid = validatePhoneNumber(input);
    setIsValidNumber(isValid);
    setAnswer(data, { value: input });
    setFormValidation(isValid);
  };

  return (
    <div className={`w-full ${formClass}`}>
      <div className="form-title pt-3">
        {data?.label}
        {data?.validate?.required ? (
          <span className={!formValidation ? "text-red-700" : "text-gray-600"}>
            {" "}
            *
          </span>
        ) : null}
      </div>
      <form className="flex-grow" onSubmit={(e) => e.preventDefault()}>
        <div className="radio-common">
          <label className="relative">
            <input
              type="tel"
              id={data?.key}
              value={phoneNumber}
              onChange={handlePhoneChange}
              placeholder="Enter phone number"
              disabled={data?.disabled || false}
              className={`text-field text-input ${!isValidNumber ? 'border-red-700' : ''}`}
            />
            {phoneNumber && !isValidNumber && (
              <span className="text-red-700 text-sm">Invalid phone number</span>
            )}
            {data?.description && (
              <div className="px-2 text-gray-500 text-sm">
                {data?.description}
              </div>
            )}
          </label>
        </div>
      </form>
      {data?.validate?.required && !formValidation ? (
        <span className="text-red-700 font-light text-xs">
          {data?.invalidMessage || "Required Field"}
        </span>
      ) : null}
    </div>
  );
};

export default Telephone;
