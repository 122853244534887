import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import JourneyRenderer from "./pages/journeyRenderer";
import NotFound from "./pages/notFound";
import ThankYouPage from "./pages/thankYou";
import AppointmentDetails from "./pages/appointmentDetails";

const RoutesPage = () => {
  return (
    // <Router>
      <Routes>
        <Route path="/:journeyId" element={<JourneyRenderer />} />
        <Route path="/404_not_found" element={<NotFound />} />
        <Route path="/thank-you" element={<ThankYouPage />} />
        <Route path="*" element={<Navigate to="/404_not_found" replace />} />
      </Routes>
    // </Router>
  );
};

export default RoutesPage;
