import { useState, useEffect, useRef, useContext } from "react";
import "./common.scss";
import Loader from "./loader";
import FormRendererContext from "../../services/context/FormRendererContext";
import { callAthenaGetApi } from "../../utils/athena";
import DropDownWithSearchForAllergy from "./DropDownWithSearchForAllergy";
import DateInput from "./DateInput";
import DropDownSelector from "./DropDownSelector";
import AddBoxIcon from "@mui/icons-material/AddBox";
import DeleteIcon from "@mui/icons-material/Delete";

const HealthHistoryAllergyComponent = ({
  data,
  setAnswer,
  formValidation = true,
  formClass,
}) => {
  const [healthHistoryFormId, setHealthHistoryFormId] = useState(5792);

  const [allergies, setAllergies] = useState(null);
  const [allergiesForm, setAllergiesForm] = useState([]);
  const [isLoading, setIsloading] = useState(true);

  const { answerData, hashIdFromQP, allergyData, setAllergyData } =
    useContext(FormRendererContext);

  useEffect(() => {
    if (allergyData) {
      setAllergies(allergyData);
      setIsloading(false);
    } else updateHealthHistoryFormId();
  }, [data]);

  const updateHealthHistoryFormId = async () => {
    const API_URL = process.env.REACT_APP_SCHEDULER_URL;
    const getHealthHistoryApiUrl = data?.getHealthHistoryApiUrl;

    const API_COMPLETE_URL = getHealthHistoryApiUrl
      .replace("${API_URL}", API_URL)
      .replace("${hashIdFromQP}", hashIdFromQP);
    const res = await callAthenaGetApi(API_COMPLETE_URL);

    const { HBx } = res.data;

    const justAllergiesForm = HBx.find(
      (form) => form.formname === "Just Allergies"
    );
    const formId = justAllergiesForm ? justAllergiesForm.formid : null;
    if (formId) {
      setHealthHistoryFormId(formId);
      updateHealthHistoryAllergy(formId);
    }
    return formId;
  };

  const generateUniqueKey = () =>
    Date.now().toString(36) + Math.random().toString(36).substring(2);

  const updateHealthHistoryAllergy = async (formId) => {
    const API_URL = process.env.REACT_APP_SCHEDULER_URL;
    const getHealthHistoryAllergyApiUrl = data?.getHealthHistoryAllergyApiUrl;

    const API_COMPLETE_URL = getHealthHistoryAllergyApiUrl
      .replace("${API_URL}", API_URL)
      .replace("${hashIdFromQP}", hashIdFromQP)
      .replace("${healthHistoryFormId}", formId);
    const res = await callAthenaGetApi(API_COMPLETE_URL);

    const { HBx } = res.data;
    let newAllergiesForm = [];

    HBx.forEach((item) => {
      const { fielddata } = item;
      let formattedQuesObj = null;
      for (let key in fielddata) {
        formattedQuesObj = null;
        if (fielddata.hasOwnProperty(key)) {
          // Ensures the key is a property of fielddata and not inherited
          let quesObj = fielddata[key];
          if (
            quesObj?.inputtype === "DROPDOWN" &&
            key === "allergyreactionid"
          ) {
            formattedQuesObj = {
              ...quesObj,
              label: "What is your typical reaction to these allergies?",
              key: key,
              subKey: key,
              type: "select",
              data: {
                values: [],
              },
              selectedOption: "",
              answer: {
                value: "",
              },
            };

            quesObj?.dropdownvalues?.forEach((option) => {
              formattedQuesObj.data.values.push({
                label: option[1],
                value: option[0],
              });
            });
          }
          if (quesObj?.inputtype === "DROPDOWN" && key === "severityid") {
            formattedQuesObj = {
              ...quesObj,
              label: "How severe is your allergic reaction?",
              key: key,
              subKey: key,
              type: "select",
              data: {
                values: [],
              },
              selectedOption: "",
              answer: {
                value: "",
              },
            };

            quesObj?.dropdownvalues?.forEach((option) => {
              formattedQuesObj.data.values.push({
                value: option?.id,
                label: option?.description,
              });
            });
          }
          if (quesObj?.inputtype === "DATE" && key === "onsetdate") {
            formattedQuesObj = {
              ...quesObj,
              label: "Onset Date",
              key: key,
              subKey: key,
              type: "datetime",
              maxDate: "today",
              selectedOption: "",
              answer: {
                value: "",
              },
            };
          }
        }
        formattedQuesObj && newAllergiesForm.push(formattedQuesObj);
      }
    });
    newAllergiesForm.forEach((item, index) => {
      // Update the key with a new format; here we're appending `_new` for demonstration
      item.key = `${item.key}_${generateUniqueKey()}`;
    });
    setAllergiesForm(newAllergiesForm);
    setAllergies([
      {
        components: [
          {
            label: "Please enter the name of your allergy",
            key: generateUniqueKey(),
            subKey: "searchAllergy",
            type: "searchAllergy",
            apiForSearch: data?.getAllergyApiUrl,
            selectedOption: "",
            answer: {
              value: "",
            },
          },
          ...structuredClone(newAllergiesForm),
        ],
      },
    ]);
    setIsloading(false);
  };

  const fetchOptions = async (query) => {
    const API_URL = process.env.REACT_APP_SCHEDULER_URL;
    const getAllergyApiUrl = data?.getAllergyApiUrl;

    const API_COMPLETE_URL = getAllergyApiUrl
      .replace("${API_URL}", API_URL)
      .replace("${hashIdFromQP}", hashIdFromQP)
      .replace("${searchParam}", query);

    try {
      const res = await callAthenaGetApi(API_COMPLETE_URL);
      const { HBx } = res.data;
      return HBx || []; // Make sure it returns an array of options
    } catch (error) {
      console.error("Error fetching options:", error);
      return [];
    }
  };

  // Add a new allergy entry
  const handleAddAllergy = () => {
    const newAllergyFormWithNewKey = structuredClone(allergiesForm);
    newAllergyFormWithNewKey.forEach((item, index) => {
      // Update the key with a new format; here we're appending `_new` for demonstration
      item.key = `${item.key}_${generateUniqueKey()}`;
    });

    setAllergies([
      ...allergies,
      {
        components: [
          {
            label: "Please enter the name of your allergy",
            key: generateUniqueKey(),
            type: "searchAllergy",
            subKey: "searchAllergy",
            apiForSearch: data?.getAllergyApiUrl,
            selectedOption: "",
            answer: {
              value: "",
            },
          },
          ...newAllergyFormWithNewKey,
        ],
      },
    ]);
  };

  // Remove an allergy entry
  const handleRemoveAllergy = (index) => {
    if (allergies?.length > 1)
      setAllergies(allergies.filter((_, i) => i !== index));
  };

  const handleUpdateAnswer = (index, componentIndex, value, label) => {
    let updatedAllergies = [...allergies];
    updatedAllergies[index].components[componentIndex].selectedOption = value;
    updatedAllergies[index].components[componentIndex].answer.value = value;
    if (label) {
      updatedAllergies[index].components[componentIndex].answer.label = label;
    }

    setAllergyData(updatedAllergies);
  };

  return (
    <>
      <div className={`w-full `}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="space-y-4 sm:space-y-6 md:space-y-8 lg:space-y-10">
              {!!allergies &&
                allergies?.length &&
                allergies?.map((allergy, index) => {
                  return (
                    <div className="p-4 bg-white rounded-md sm:p-4 md:p-6 lg:p-8 shadow-md border border-gray-200">
                      <div className="form-width mx-auto flex justify-end my-1 cursor-pointer">
                        <div onClick={() => handleRemoveAllergy(index)}>
                          <DeleteIcon />
                        </div>
                      </div>
                      {allergy &&
                        allergy?.components &&
                        allergy.components?.map((item, componentIndex) => {
                          return (
                            <>
                              {/* {console.log(item)} */}
                              {item?.type === "datetime" && (
                                <div className="form-width mx-auto flex justify-center my-3">
                                  <DateInput
                                    data={item}
                                    setAnswer={(data, { value, label }) => {
                                      handleUpdateAnswer(
                                        index,
                                        componentIndex,
                                        value
                                      );
                                      setAnswer(data, { value, label });
                                    }}
                                    formClass={formClass}
                                    formValidation={formValidation}
                                  />
                                </div>
                              )}

                              {item?.type === "select" && (
                                <div className="form-width mx-auto flex justify-center my-3">
                                  <DropDownSelector
                                    data={item}
                                    setAnswer={(data, { value, label }) => {
                                      handleUpdateAnswer(
                                        index,
                                        componentIndex,
                                        value
                                      );
                                      setAnswer(data, { value, label });
                                    }}
                                  />
                                </div>
                              )}

                              {item?.type === "searchAllergy" && (
                                <div className="form-width mx-auto flex justify-center my-3">
                                  <DropDownWithSearchForAllergy
                                    label={item?.label}
                                    selectedOptionData={{
                                      label: item?.answer?.label || "",
                                    }}
                                    fetchOptions={fetchOptions}
                                    onOptionSelect={(option) => {
                                      handleUpdateAnswer(
                                        index,
                                        componentIndex,
                                        option?.allergyid,
                                        option?.allergenname
                                      );
                                      setAnswer(item, {
                                        value: option?.allergyid,
                                        label: option?.allergenname,
                                      });
                                    }}
                                    //   onOptionSelect={handleOptionSelect}
                                    displayKey={"allergenname"}
                                  />
                                </div>
                              )}
                            </>
                          );
                        })}
                    </div>
                  );
                })}
            </div>
            <div className="form-width mx-auto flex justify-end my-1 cursor-pointer">
              <div onClick={handleAddAllergy} className="px-3 pt-3">
                <AddBoxIcon sx={{ fontSize: "1.7rem" }} />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default HealthHistoryAllergyComponent;
