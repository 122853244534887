// ProgressTracker.js

import React, { useEffect, useState, useContext } from "react";
import "./index.css";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import FormRendererContext from "../../../services/context/FormRendererContext";
import CustomButton, { CustomTextButton } from "../CustomButton";
import { Stack, Box, IconButton } from "@mui/material";
import {
  NavigateBeforeOutlined,
  NavigateNextOutlined,
  PermContactCalendarOutlined,
  Person,
} from "@mui/icons-material";

import axios from "axios";
import Loader from "../loader";
import { convertTimeWithTimeZone } from "../../../utils/timezone";

const AppointmentCard = ({ doctorData, providerId, activateNextClick }) => {
  const [slots, setSlots] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [endDate, setEndDate] = useState(null);
  const [showSlots, setShowSlots] = useState([]);
  const [slotsAvailable, setSlotsAvailable] = useState(true);
  const [latestSlot, setLatestSlot] = useState(null);
  const [availableSlotsDate, setAvailableSlotsDate] = useState([]);
  const [slotsLength, setSlotsLength] = useState(2);
  const [providerTimeZone, setProviderTimeZone] = useState("America/New_York");
  const [providerTimeZoneOffset, setProviderTimeZoneOffset] = useState(-5);

  const months = [
    "January",
    "February",
    "March",
    "May",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const {
    scheduler_id,
    reason_id,
    setAppointment_id,
    appointment_id,
    department_id,
    answerData,
    setAppointmentSlotData,
    deviceType,
    patientTZ
  } = useContext(FormRendererContext);

  function getFormattedDateInUserTZ(startTimeForUser, userTZ, date) {
    // Parse date and time components
    const [month, day, year] = date.split("/").map(Number);
    const [hours, minutes] = startTimeForUser.split(":").map(Number);

    // Create a date object in UTC based on the parsed date and time
    const utcDate = new Date(Date.UTC(year, month - 1, day, hours, minutes));

    // Adjust for the user's time zone offset
    const userTimeOffset = userTZ * 60; // Convert hours to minutes
    const userTime = new Date(utcDate.getTime() + userTimeOffset * 60 * 1000);

    // Format to the specified output format
    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZone: "UTC",
      timeZoneName: "short",
    };

    // Format the adjusted time in UTC to display as if in `userTZ`
    return userTime.toLocaleString("en-US", options);
  }

  const checkForSlots = async (start, end) => {
    setIsLoading(true);
    const API_URL = `${
      process.env?.REACT_APP_SCHEDULER_URL ||
      "https://apps-sandbox.healthblox.care/scheduler"
    }/api/v1/providers/${scheduler_id}/slots?departmentId=${department_id}&reasonId=${reason_id}&providerId=${providerId}&startDate=${start}&endDate=${end}`;

    try {
      const response = await axios.get(API_URL);
      const { HBx } = response?.data;
      const { appointments } = HBx;
      const slots = {};
      const availableSlotsDate = [];
      const dayNames = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

      appointments.forEach((appointment) => {
        const date = new Date(appointment.date);
        const dateString = `${date.getDate()} ${months[date.getMonth()]}`;
        const dateStringId = appointment.date;
        const day = dayNames[date.getDay()];

        appointment.patientStartTime = convertTimeWithTimeZone(
          appointment.startTime,
          date,
          providerTimeZone,
          patientTZ
        );
        // appointment.dateObj = getFormattedDateInUserTZ(
        //   appointment.startTime,
        //   providerTimeZoneOffset,
        //   appointment?.date
        // );
        appointment.timestamp = Date.now();
        if (slots[dateStringId]) {
          slots[dateStringId].availableSlots.push(appointment);
        } else {
          slots[dateStringId] = {
            dateString,
            day,
            availableSlots: [appointment],
          };
        }

        if (!availableSlotsDate.includes(dateStringId)) {
          availableSlotsDate.push(dateStringId);
        }
      });

      const sortedAvailableSlotsDate = sortDates(availableSlotsDate);
      const showSlots = generateDateSlots(
        start,
        deviceType === "mobile" ? 2 : deviceType === "tablet" ? 3 : 5
      );

      showSlots.forEach((slot) => {
        slot.availableSlots = slots[slot.dateStringId]?.availableSlots || [];
      });

      const slotsAvailable = showSlots.some(
        (slot) => slot.availableSlots.length > 0
      );

      setSlotsAvailable(slotsAvailable);
      setAvailableSlotsDate(sortedAvailableSlotsDate);
      setLatestSlot(sortedAvailableSlotsDate[0] || null);
      setShowSlots(showSlots);
      setSlots(slots);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const setProviderTimeZoneFromDepartment = async () => {
    const API_URL = `${
      process.env?.REACT_APP_SCHEDULER_URL ||
      "https://apps-sandbox.healthblox.care/scheduler"
    }/api/v1/providers/${scheduler_id}/departments/id/${department_id}`;

    try {
      const response = await axios.get(API_URL);
      const { HBx } = response?.data;
      const { timezoneName } = HBx;
      const { timezoneOffset } = HBx;
      setProviderTimeZone(timezoneName);
      setProviderTimeZoneOffset(timezoneOffset);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const sortDates = (dateArray) => {
    return dateArray.sort((a, b) => new Date(a) - new Date(b));
  };

  const formatDate = (date) => {
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`; // Format to "MM/DD/YYYY"
  };

  const handleSlotClick = (slot) => {
    setAppointment_id(slot.appointmentId);
    setAppointmentSlotData({ slot, providerTimeZoneOffset });
    setTimeout(
      () =>
        activateNextClick(slot.appointmentId, { slot, providerTimeZoneOffset }),
      500
    );
    console.log("handleSlotClick", appointment_id);
  };

  const getPreviousDate = (dateString) => {
    const [month, day, year] = dateString.split("/").map(Number);
    const date = new Date(year, month - 1, day); // Month is 0-indexed in Date
    date.setDate(date.getDate() - 1);
    return formatDate(date); // Reuse formatDate function
  };

  const handleDateNavigation = (direction) => {
    let showSlotsTemp = [...showSlots];
    const isIncrement = direction === "increment";
    const isDecrement = direction === "decrement";
    const endDateReached =
      showSlotsTemp[showSlotsTemp.length - 1].dateStringId === endDate;
    const startDateReached = showSlotsTemp[0].dateStringId === startDate;

    if (isIncrement) {
      if (!endDateReached) {
        showSlotsTemp.shift();
        const newDateObjArray = generateDateSlots(
          showSlotsTemp[showSlotsTemp?.length - 1]?.dateStringId,
          1,
          true
        );
        showSlotsTemp.push(...newDateObjArray);
        showSlotsTemp[showSlotsTemp?.length - 1].availableSlots =
          slots[showSlotsTemp[showSlotsTemp?.length - 1]?.dateStringId]
            ?.availableSlots || [];

        const slotsAvailable = showSlotsTemp.some(
          (slot) => slot.availableSlots && slot.availableSlots.length > 0
        );

        setSlotsAvailable(slotsAvailable);
        setShowSlots(showSlotsTemp);

        if (!slotsAvailable) {
          const lastSlotDate =
            showSlotsTemp[showSlotsTemp.length - 1].dateStringId;
          const nextDate = availableSlotsDate.find(
            (dateString) => new Date(dateString) > new Date(lastSlotDate)
          );
          setLatestSlot(nextDate);
        }
      } else {
        const newStart = endDate;
        let newStartDate = new Date(newStart);
        newStartDate.setMonth(newStartDate.getMonth() + 1);
        const oneMonthAheadDateString = formatDate(newStartDate);
        if (newStart !== oneMonthAheadDateString) {
          setStartDate(newStart);
          setEndDate(oneMonthAheadDateString);
          checkForSlots(newStart, oneMonthAheadDateString);
        }
      }
    }

    if (isDecrement) {
      if (!startDateReached) {
        showSlotsTemp.pop();
        const newDateObjArray = generateDateSlots(
          getPreviousDate(showSlotsTemp[0]?.dateStringId),
          1
        );
        showSlotsTemp.unshift(...newDateObjArray);
        showSlotsTemp[0].availableSlots =
          slots[showSlotsTemp[0]?.dateStringId]?.availableSlots || [];

        // const slotsAvailable = showSlotsTemp.some(
        //   (slot) => slot.availableSlots && slot.availableSlots.length > 0
        // );

        // setSlotsAvailable(true);
        setShowSlots(showSlotsTemp);

        // if (!slotsAvailable) {
        //   const lastSlotDate =
        //     showSlotsTemp[showSlotsTemp.length - 1].dateStringId;
        //   const nextDate = availableSlotsDate.find(
        //     (dateString) => new Date(dateString) > new Date(lastSlotDate)
        //   );
        //   setLatestSlot(nextDate);
        // }
      } else {
        let newEnd = startDate;
        const today = new Date();
        let newEndDate = new Date(newEnd);
        newEndDate.setMonth(newEndDate.getMonth() - 1);
        const oneMonthBehindDateString =
          newEndDate > today ? formatDate(newEndDate) : formatDate(today);
        if (newEnd !== oneMonthBehindDateString) {
          setEndDate(newEnd);
          setStartDate(oneMonthBehindDateString);
          checkForSlots(oneMonthBehindDateString, newEnd);
        }
      }
    }
  };

  const checkNextMonthSlots = () => {
    const newStart = endDate;
    let newStartDate = new Date(newStart);
    newStartDate.setMonth(newStartDate.getMonth() + 1);
    const oneMonthAheadDateString = formatDate(newStartDate);
    if (newStart !== oneMonthAheadDateString) {
      setStartDate(newStart);
      setEndDate(oneMonthAheadDateString);
      checkForSlots(newStart, oneMonthAheadDateString);
    }
  };

  const handleNextAvailableDateClick = (latestDate) => {
    const showSlots = generateDateSlots(
      latestDate,
      deviceType === "mobile" ? 2 : deviceType === "tablet" ? 3 : 5
    );

    showSlots.forEach((slot) => {
      slot.availableSlots = slots[slot.dateStringId]?.availableSlots || [];
    });

    const slotsAvailable = showSlots.some(
      (slot) => slot.availableSlots.length > 0
    );

    setSlotsAvailable(slotsAvailable);
    setShowSlots(showSlots);
  };

  const generateDateSlots = (startDate, numDays, fromNextDay = false) => {
    const slots = [];
    const optionsDate = { day: "2-digit", month: "long" };
    const optionsDay = { weekday: "short" };

    // Parse the start date from "MM/DD/YYYY" format
    const [month, day, year] = startDate.split("/").map(Number); // Convert to numbers
    const startDateObj = new Date(year, month - 1, day); // Month is 0-indexed

    if (fromNextDay) startDateObj.setDate(startDateObj.getDate() + 1);

    for (let i = 0; i < numDays; i++) {
      const date = new Date(startDateObj);
      date.setDate(date.getDate() + i);
      const dateString = date
        .toLocaleDateString("en-GB", optionsDate)
        .replace(".", "");
      const dayName = date
        .toLocaleDateString("en-GB", optionsDay)
        .toUpperCase();

      // Create dateStringId in "MM/DD/YYYY" format
      const dateIdString = `${String(date.getMonth() + 1).padStart(
        2,
        "0"
      )}/${String(date.getDate()).padStart(2, "0")}/${date.getFullYear()}`;
      slots.push({
        dateString,
        day: dayName,
        dateStringId: dateIdString, // Set dateStringId in "MM/DD/YYYY" format
      });
    }
    return slots;
  };

  useEffect(() => {
    const today = new Date();
    const todayDateString = formatDate(today);
    const oneMonthAhead = new Date(today); // create a new Date object to avoid modifying the original one
    oneMonthAhead.setMonth(today.getMonth() + 1);
    const oneMonthAheadDateString = formatDate(oneMonthAhead);

    setStartDate(todayDateString);
    setEndDate(oneMonthAheadDateString);
    checkForSlots(todayDateString, oneMonthAheadDateString);
    setProviderTimeZoneFromDepartment();
  }, [deviceType]);

  return (
    <Box
      my={0}
      mx={0}
      justifyContent={"center"}
      justifySelf={"center"}
      className="w-full"
    >
      <div className="appointment-card grid grid-cols-12 gap-4">
        <div className="col-span-12 lg:col-span-5 xl:col-span-5">
          <Stack direction={"row"} spacing={2}>
            {doctorData?.display_image ? (
              <img
                src={doctorData?.display_image}
                alt="Display Image"
                style={{ height: 140, width: 140, borderRadius: 20 }}
              />
            ) : (
              <Person sx={{ height: 140, fontSize: 140 }} />
            )}
            <Box
              display={"block"}
              flexDirection={"column"}
              justifyContent={"space-between"}
              height={140}
              className="m-auto"
            >
              <Box>
                <div className="appointment-card-title">
                  {doctorData?.displayName}
                </div>
                <div className="appointment-card-subtitle">
                  {doctorData?.specialty}
                </div>
              </Box>
              <div className="mt-5">
                <CustomButton
                  className="appointment-card-button"
                  size={"small"}
                  variant={"text"}
                  startIcon={<PermContactCalendarOutlined />}
                >
                  View Details
                </CustomButton>
              </div>
            </Box>
          </Stack>
        </div>
        <div className="col-span-12 lg:col-span-7 mx-auto xl:col-span-7 my-auto">
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"flex-start"}
            justifyContent={"space-between"}
          >
            {isLoading ? (
              <Loader />
            ) : (
              <>
                {!slotsAvailable ? (
                  <>
                    {latestSlot ? (
                      <div className="h-full next-available-button">
                        <CustomButton
                          variant={"outlined"}
                          size={"small"}
                          onClick={() =>
                            handleNextAvailableDateClick(latestSlot)
                          }
                        >
                          Next available appointment is on {latestSlot}
                        </CustomButton>
                      </div>
                    ) : (
                      <div className="h-full next-available-button text-center">
                        <div className="text-xl">No slots available</div>
                        <CustomTextButton
                          style={{ color: "#1976d2" }}
                          size={"small"}
                          onClick={() => checkNextMonthSlots()}
                        >
                          Check for next month?
                          {latestSlot}
                        </CustomTextButton>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {showSlots && showSlots.length !== 0 ? (
                      <div className="flex">
                        <div className="p-1 flex-none">
                          <IconButton
                            onClick={() => handleDateNavigation("decrement")}
                          >
                            <NavigateBeforeOutlined />
                          </IconButton>
                        </div>
                        <div className=" grow w-full">
                          <div className="flex slot-table w-full">
                            {showSlots &&
                              showSlots.map((date, index) => {
                                return (
                                  <div className="flex flex-col">
                                    <div className="flex flex-col date-row">
                                      <div className="day-subtitle mx-auto">
                                        {date?.day}
                                      </div>
                                      <div className="day-title mx-auto">
                                        {date?.dateString}
                                      </div>
                                    </div>
                                    <div className="flex flex-col py-2">
                                      {slotsAvailable && (
                                        <>
                                          {date.availableSlots.map(
                                            (slot, slotIndex) => {
                                              return (
                                                <div
                                                  className={
                                                    slotsLength > slotIndex
                                                      ? "text-center py-1"
                                                      : "text-center py-1 hidden"
                                                  }
                                                >
                                                  <div
                                                    className="slot-button hover:bg-white"
                                                    onClick={() =>
                                                      handleSlotClick(slot)
                                                    }
                                                  >
                                                    {slot.patientStartTime}
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                          {slotsLength === 2 ? (
                            <div
                              className="flex justify-center text-center my-1"
                              onClick={() => setSlotsLength(1000)}
                            >
                              <div className="flex view-button">
                                View More{" "}
                                <span className="my-auto pl-1">
                                  <IoIosArrowDown />
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="flex justify-center text-center my-1"
                              onClick={() => setSlotsLength(2)}
                            >
                              <div className="flex view-button">
                                View Less{" "}
                                <span className="my-auto pl-1">
                                  <IoIosArrowUp />
                                </span>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="p-1 flex-none">
                          <IconButton
                            onClick={() => handleDateNavigation("increment")}
                          >
                            <NavigateNextOutlined />
                          </IconButton>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            )}
          </Box>
        </div>
      </div>
    </Box>
  );
};

export default AppointmentCard;
