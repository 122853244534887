import { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import "./common.scss";
import { currentFormattedDateAndTime, trackEvent } from "../../utils/mixPanel";

const OptionSelector = ({ data, setOption }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  return (
    <>
      {data.label && <div>{data.label}</div>}
      {data.values && data.values.length ? (
        <>
          <form>
            {data.values.map((item) => {
              return (
                <div
                  className="radio-common my-3"
                  onClick={() => {
                    setSelectedOption(item.id);
                    setOption(item.id);
                    trackEvent(`option-selector ${data?.label} clicked`, {
                      question: data?.label,
                      questionKey: data?.key,
                      clickedAt: currentFormattedDateAndTime(),
                      // selectedValue: item.label
                      data: "Answered"
                    })
                  }}
                >
                  <div className="mr-2 flex justify-between text-field text-input w-full px-w py-3 cursor_pointer option_selector">
                    <span className="self-center">{item.label}</span>
                    <IoIosArrowForward className="self-center" />
                  </div>
                </div>
              );
            })}
          </form>
        </>
      ) : (
        <div></div>
      )}
    </>
  );
};
export default OptionSelector;
