import React, { useContext } from "react";
import { RiThumbUpLine, RiHeartFill, RiStarFill } from "react-icons/ri";
import "./thankYou.scss"; // Import your CSS file
import ThemeSelectorContext from "../../services/context/ThemeSelectorContext";
import AppointmentDetails from "../appointmentDetails";

const ThankYouPage = () => {
  const { theme } = useContext(ThemeSelectorContext);
  return (
    <>
      {theme && theme !== "theme2" ? (
        <div className="thankyou-container h-screen bg-gray-100 py-48">
          <h1>Thank You!</h1>
          <p>
            We appreciate your time and effort. Your submission has been
            received.
          </p>
          {/* <div className="icons">
            <RiThumbUpLine className="icon" />
            <RiHeartFill className="icon" />
            <RiStarFill className="icon" />
          </div> */}
        </div>
      ) : (
        <AppointmentDetails />
      )}
    </>
  );
};

export default ThankYouPage;
