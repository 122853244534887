import { useState, useEffect, useContext } from "react";
import "./common.scss";
import "./checkbox.css";
import ThemeSelectorContext from "../../services/context/ThemeSelectorContext";
import { currentFormattedDateAndTime, trackEvent } from "../../utils/mixPanel";

const CheckBoxSelector = ({ data, setAnswer, formValidation }) => {
  const [selectedList, setSelectedList] = useState([]);
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [validForm, setFormValidation] = useState(formValidation);

  const { theme } = useContext(ThemeSelectorContext);

  const handleCheckboxClick = (value, label) => {
    if (selectedList?.includes(value)) {
      let optionsData = [...selectedList];
      optionsData.splice(optionsData.indexOf(value), 1);
      let labelsData = [...selectedLabels];
      labelsData.splice(labelsData.indexOf(label), 1);
      setSelectedList(optionsData);
      setSelectedLabels(labelsData);
      setAnswer(data, { value: optionsData, labels: labelsData });
      setFormValidation(true);
      // setAnswer({ question: data.label, answer: optionsData });
      trackEvent(`checkbox ${data?.label} clicked`, {
        question: data?.label,
        questionKey: data?.key,
        clickedAt: currentFormattedDateAndTime(),
        checked: true,
      });
    } else {
      setSelectedList([...selectedList, value]);
      setSelectedLabels([...selectedLabels, label]);
      setAnswer(data, {
        value: [...selectedList, value],
        label: [...selectedLabels, label],
      });
      setFormValidation(true);
      // setAnswer({ question: data.label, answer: [...selectedList, value] });
      trackEvent(`checkbox ${data?.label} clicked`, {
        question: data?.label,
        questionKey: data?.key,
        clickedAt: currentFormattedDateAndTime(),
        checked: false,
      });
    }
  };

  useEffect(() => {
    setFormValidation(formValidation);
    if (data?.answer) {
      setSelectedList(data.answer?.value);
    }
  }, [formValidation]);
  return (
    <div className="w-full">
      <div className="form-title pt-3 mb-2">{data.label}</div>
      {data?.validate?.required ? (
        <span className={!validForm ? "text-red-700" : ""}> * </span>
      ) : (
        <></>
      )}
      {data?.validate?.required && !validForm ? (
        <span className="text-red-700 font-light text-xs">
          {" "}
          Required Field{" "}
        </span>
      ) : (
        <></>
      )}
      {data.values && data.values.length ? (
        <>
          <form>
            {data.values.map((item, index) => {
              return (
                // <div
                //   className="radio-common my-2"
                //   // onClick={() => handleCheckboxClick(item.value)}
                // >
                //   <label>
                //     <input
                //       type="checkbox"
                //       key={index}
                //       value={item.value}
                //       checked={selectedList.includes(item.value)}
                //       className="mr-2"
                //       onChange={() => handleCheckboxClick(item.value)}
                //     />
                //     <span className="form-title">{item.label}</span>
                //   </label>
                // </div>
                <>
                  {theme && theme !== "theme2" ? (
                    <div className="checkbox-wrapper-4 radio-common">
                      <input
                        className="inp-cbx"
                        onChange={() =>
                          handleCheckboxClick(item.value, item.label)
                        }
                        id={data.key + "" + item.value}
                        key={index}
                        value={item.value}
                        checked={selectedList.includes(item.value)}
                        type="checkbox"
                      />
                      <label
                        className="cbx"
                        htmlFor={data.key + "" + item.value}
                      >
                        <span className="mr-1 span-first">
                          <svg width="12px" height="10px">
                            <use
                              xlinkHref={`#check${data.key + "" + item.value}`}
                            ></use>
                          </svg>
                        </span>
                        <span className="form-title cbx-label">
                          {item.label}
                        </span>
                      </label>
                      <svg className="inline-svg">
                        <symbol
                          id={`check${data.key + "" + item.value}`}
                          viewBox="0 0 12 10"
                        >
                          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                        </symbol>
                      </svg>
                    </div>
                  ) : (
                    <div
                      onClick={() =>
                        handleCheckboxClick(item.value, item.label)
                      }
                      className={
                        selectedList?.includes(item.value)
                          ? "primary_checked"
                          : "outlined_checked"
                      }
                    >
                      <div>{item.label}</div>
                    </div>
                  )}
                </>
              );
            })}
          </form>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
export default CheckBoxSelector;
