import { useState, useEffect, useContext } from "react";
import "./common.scss";
import FormRendererContext from "../../services/context/FormRendererContext";
import { currentFormattedDateAndTime, trackEvent } from "../../utils/mixPanel";

const EmailPhone = ({ data, setAnswer, formValidation }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [validForm, setFormValidation] = useState(formValidation);
  const { answerData } = useContext(FormRendererContext);

  const handleFocus = (e) => {
    trackEvent(`email-phone ${data?.label} clicked`, {
      question: data?.label,
      questionKey: data?.key,
      clickedAt: currentFormattedDateAndTime(),
    });
  };
  const handleBlur = (e) => {
    trackEvent(`email-phone ${data?.label} filled`, {
      question: data?.label,
      questionKey: data?.key,
      clickedAt: currentFormattedDateAndTime(),
      // filledValue: selectedOption,
      data: "Answered"
    });
  };

  useEffect(() => {
    setFormValidation(formValidation);
    if (data?.answer) {
      setSelectedOption(data.answer?.value);
    }
    if (answerData?.[data?.key]) {
      setSelectedOption(answerData?.[data?.key]?.value);
    }
  }, []);

  return (
    <div className=" w-full">
      <div className="form-title pt-3">
        {data.label}
        {data?.validate?.required ? (
          <span className={!formValidation ? "text-red-700" : "text-gray-600"}>
            {" "}
            *{" "}
          </span>
        ) : (
          <></>
        )}
      </div>
      <form>
        <div className="radio-common">
          <label>
            <input
              type="text"
              value={selectedOption}
              onChange={(e) => {
                setSelectedOption(e.target.value);
                setAnswer(data, { value: e.target.value });
                setFormValidation(true);
              }}
              onFocus={(e) => {
                handleFocus(e);
              }}
              onBlur={(e) => {
                handleBlur(e);
              }}
              placeholder={data?.placeholder}
              className="text-field text-input"
            />
          </label>
        </div>
      </form>
      {data?.validate?.required && !formValidation ? (
        <span className="text-red-700 font-light text-xs">
          {data?.invalidMessage || "Required Field"}
        </span>
      ) : (
        <></>
      )}
    </div>
  );
};
export default EmailPhone;
