import React, { useContext, useState } from "react";
import { RiThumbUpLine, RiHeartFill, RiStarFill } from "react-icons/ri";
import "./index.scss"; // Import your CSS file
import FormRendererContext from "../../services/context/FormRendererContext";
import { FaCalendarAlt } from "react-icons/fa";
import calendar from "../../assets/calendar.png";
import { IoLocation } from "react-icons/io5";
import { FaUserDoctor } from "react-icons/fa6";
import { FaLaptopFile } from "react-icons/fa6";
import { MdOutlineTaskAlt } from "react-icons/md";
const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const AppointmentDetails = () => {
  const { appointmentData } = useContext(FormRendererContext);
  let appointmentDate = null;
  if (appointmentData) {
    const date = new Date(appointmentData?.date);
    const mnth = month[date.getMonth()];
    const day = date.getDate();
    appointmentDate = mnth + " " + day;
  }
  return (
    <div className="appointment-details-container pt-10 pb-5 px-5">
      <div className="font-semi-bold text-center text-3xl font_georgia">
        Here is information regarding your scheduled appointment:
      </div>
      <div className="py-5">
        <img src={calendar} className="h-28 w-28 mx-auto" />
      </div>
      <div className="text-center">
        <div className="inline-block">
          <div className="text-base flex gap-4 justify-start py-1 tracking-wide">
            <FaCalendarAlt color="rgba(247,148,32,255)" size="1.2em" />{" "}
            <span>
              {" "}
              {appointmentDate || "May 8"} @{" "}
              {appointmentData?.startTime || "8:00 AM"}
            </span>
          </div>
          <div className="text-base flex gap-4 justify-start py-1 tracking-wide">
            <IoLocation color="rgba(247,148,32,255)" size="1.2em" />{" "}
            <span> ArimaHealth</span>
          </div>
          <div className="text-base flex gap-4 justify-start py-1 tracking-wide">
            <FaUserDoctor color="rgba(247,148,32,255)" size="1.2em" />{" "}
            <span> Seong Kim, PAC</span>
          </div>
          <div className="text-base flex gap-4 justify-start py-1 tracking-wide">
            <FaLaptopFile color="rgba(247,148,32,255)" size="1.2em" />{" "}
            <span>
              {" "}
              Online, {appointmentData?.appointmenttype || "video visit"}
            </span>
          </div>
        </div>
      </div>
      <div className="pt-8 pb-2 font-semi-bold text-2xl">
        Complete tasks prior to visit:
      </div>
      <div className="flex gap-4 px-2 py-1 text-base">
        <MdOutlineTaskAlt color="green" size="1.8em" />{" "}
        <span className="content-center">Upload Insurance card</span>
      </div>
      <div className="flex gap-4 px-2 py-1 text-base">
        <MdOutlineTaskAlt color="green" size="1.8em" />{" "}
        <span className="content-center">Upload credit card</span>
      </div>
      <div className="flex gap-4 px-2 py-1 text-base">
        <MdOutlineTaskAlt color="green" size="1.8em" />{" "}
        <span className="content-center">Intake questionnaire in email</span>
      </div>
      <div className="py-5 italic text-center text-gray-700 text-sm">
        video link will be live 2 hours prior to appointment{" "}
      </div>
      <div className="py-1">
        <button className="primary_button primary_large_button">
          Access Video Visit Link Here
        </button>
      </div>
    </div>
  );
};

export default AppointmentDetails;
