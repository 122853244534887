import { useState, useEffect, useContext } from "react";
import "./common.scss";
import { currentFormattedDateAndTime, trackEvent } from "../../utils/mixPanel";
import FormRendererContext from "../../services/context/FormRendererContext";

const Email = ({ data, setAnswer, formValidation, formClass }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [validForm, setFormValidation] = useState(formValidation);
  const { answerData } = useContext(FormRendererContext);

  useEffect(() => {
    setFormValidation(formValidation);
    if (data?.answer) {
      setSelectedOption(data.answer?.value);
      setFormValidation(true);
    }
    if (answerData?.[data?.key]) {
      setSelectedOption(answerData?.[data?.key]?.value);
    }
  }, [formValidation]);

  const handleFocus = (e) => {
    trackEvent(`email ${data?.label} clicked`, {
      question: data?.label,
      questionKey: data?.key,
      clickedAt: currentFormattedDateAndTime(),
    });
  };
  const handleBlur = (e) => {
    trackEvent(`email ${data?.label} filled`, {
      question: data?.label,
      questionKey: data?.key,
      clickedAt: currentFormattedDateAndTime(),
      // filledValue: selectedOption,
      data: "Answered"
    });
  };

  return (
    <div className={`w-full ${formClass}`}>
      <div className="form-title pt-3">
        {data.label}

        {data?.validate?.required ? (
          <span className={!validForm ? "text-red-700" : ""}> * </span>
        ) : (
          <></>
        )}
      </div>
      <form className="flex-grow">
        <div className="radio-common my-2">
          <label>
            <input
              type="email"
              value={selectedOption}
              onChange={(e) => {
                setSelectedOption(e.target.value);
                setAnswer(data, { value: e.target.value });
                setFormValidation(true);
              }}
              onFocus={(e) => {
                handleFocus(e);
              }}
              onBlur={(e) => {
                handleBlur(e);
              }}
              className="text-field text-input"
            />
          </label>
          {data?.description && (
            <div className="text-gray-500 font-light text-sm pt-2 pb-1">
              {data.description}
            </div>
          )}

          {data?.validate?.required && !validForm ? (
            <span className="text-red-700 font-light text-xs">
              {" "}
              Required Field{" "}
            </span>
          ) : (
            <></>
          )}
        </div>
      </form>
    </div>
  );
};
export default Email;
