import { useEffect, useState } from "react";
import "./common.scss";

const ContentRenderer = ({ data }) => {
  useEffect(() => {
    if (data.html) {
      const divId = "html-element" + data?.key;
      const container = document.getElementById(divId);
      if(container) container.innerHTML = data.html;
    }
  });
  return (
    <div className="content-renderer">
      {data.html ? <div id={"html-element" + data?.key} className={data?.customClass}></div> : <></>}
    </div>
  );
};
export default ContentRenderer;
