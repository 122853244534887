import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
//Import Mixpanel SDK
import mixpanel from "mixpanel-browser";
 
// Near entry of your product, init Mixpanel
mixpanel.init("a269bdcac72d1a53ec46b10068fb8033", {
  debug: true,
  track_pageview: true,
  persistence: "localStorage",
});

Sentry.init({
  dsn: "https://24869d0c1d0ed091978bc6c56cf49a11@o4504615382548480.ingest.us.sentry.io/4508251774844928",
  integrations: [],
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
