import { useState, useEffect } from "react";
import "./common.scss";

const TextArea = ({ data, setAnswer, formValidation, formClass }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [validForm, setFormValidation] = useState(formValidation);

  useEffect(() => {
    setFormValidation(formValidation);
    if (data?.answer) {
      setSelectedOption(data.answer?.value);
    }
  }, [formValidation]);

  return (
    <div className={`w-full ${formClass}`}>
      <div className="form-title pt-3">{data.label}</div>

      {data?.validate?.required ? (
        <span className={!validForm ? "text-red-700" : ""}> * </span>
      ) : (
        <></>
      )}
      {data?.validate?.required && !validForm ? (
        <span className="text-red-700 font-light text-xs">
          {" "}
          Required Field{" "}
        </span>
      ) : (
        <></>
      )}
      <form className="flex-grow">
        <div className="radio-common my-2">
        <textarea
            value={selectedOption}
            onChange={(e) => {
              setSelectedOption(e.target.value);
              setAnswer(data, { value: e.target.value, time: new Date() });
              setFormValidation(true);
            }} 
            rows="5"
            className="text-field text-input py-1"
            />
        </div>
      </form>
    </div>
  );
};
export default TextArea;
