import { useState, useEffect, useContext } from "react";
import "./common.scss";
import FormRendererContext from "../../services/context/FormRendererContext";
import { currentFormattedDateAndTime, trackEvent } from "../../utils/mixPanel";

const AmericanPhoneField = ({
  data,
  setAnswer,
  formValidation = true,
  formClass,
}) => {
  const [selectedOption, setSelectedOption] = useState(
    data.answer?.value || ""
  );
  const [validForm, setFormValidation] = useState(formValidation);
  const { answerData } = useContext(FormRendererContext);

  const handleFocus = (e) => {
    trackEvent(`american-phone-field ${data?.label} clicked`, {
      question: data?.label,
      questionKey: data?.key,
      clickedAt: currentFormattedDateAndTime(),
    });
  };
  const handleBlur = (e) => {
    trackEvent(`american-phone-field ${data?.label} filled`, {
      question: data?.label,
      questionKey: data?.key,
      clickedAt: currentFormattedDateAndTime(),
      // filledValue: selectedOption
      data: "Answered",
    });
  };
  const formatPhoneNumber = (value) => {
    // Remove all non-digit characters
    const cleaned = value.replace(/\D/g, "");

    // Add formatting as per the NANP (North American Numbering Plan)
    if (cleaned.length <= 3) {
      return cleaned;
    } else if (cleaned.length <= 6) {
      return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`;
    } else {
      return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(
        6,
        10
      )}`;
    }
  };

  useEffect(() => {
    setFormValidation(formValidation);
    if (data?.answer) {
      setSelectedOption(data.answer?.value);
      setFormValidation(true);
    } else setSelectedOption("");

    if (answerData?.[data?.key]) {
      setSelectedOption(answerData?.[data?.key]?.value);
    }
  }, [data, formValidation]);

  return (
    <div className={`w-full ${formClass}`}>
      <div className="form-title pt-3">
        {data?.label}
        {data?.validate?.required ? (
          <span className={!validForm ? "text-red-700" : "text-gray-600"}>
            {" "}
            *
          </span>
        ) : (
          <></>
        )}
      </div>
      <form className="flex-grow">
        <div className="radio-common">
          <label className="relative">
            <input
              type="text"
              disabled={data?.disabled || false}
              value={selectedOption}
              onChange={(e) => {
                setSelectedOption(formatPhoneNumber(e.target.value));
                setAnswer(data, { value: formatPhoneNumber(e.target.value) });
                setFormValidation(true);
              }}
              onFocus={(e) => {
                handleFocus(e);
              }}
              onBlur={(e) => {
                handleBlur(e);
              }}
              placeholder={data?.suffix ? "" : data?.placeholder}
              className={
                data?.suffix
                  ? "text-field text-input text_white"
                  : "text-field text-input"
              }
            />
            {data?.suffix && (
              <div
                className="editable_div absolute w-full"
                onClick={() => {
                  var div = document.getElementById(data?.label + "editable");
                  div.focus();
                }}
              >
                <div
                  contenteditable="false"
                  className="editable_span inline-block pr-1"
                  id={data?.label + "editable"}
                >
                  {selectedOption}
                </div>
                <div
                  contenteditable="false"
                  className="non_editable_span inline-block"
                >
                  {data.suffix}
                </div>
              </div>
            )}
          </label>
        </div>
      </form>
      {data?.validate?.required && !validForm ? (
        <span className="text-red-700 font-light text-xs">
          {data?.invalidMessage || "Required Field"}
        </span>
      ) : (
        <></>
      )}
    </div>
  );
};
export default AmericanPhoneField;
