import React, { useContext, useState } from "react";

import "./Login.scss";
import FormRendererContext from "../../services/context/FormRendererContext";
import Password from "../../components/common/Password";
import EmailPhone from "../../components/common/EmailPhone";
import SingleCheckBox from "../../components/common/SingleCheckBox";

const LoginPage = () => {
  const {
    setComponents,
    loginData,
    setLoginData,
    userDetailsData,
    setUserDetailsData,
    setHBxEmail,
  } = useContext(FormRendererContext);

  const [isNextButtonActive, setNextButtonActive] = useState(false);

  function handleClickForLogin() {
    let updatedUserDetails = JSON.parse(JSON.stringify(userDetailsData));
    for (let i = 0; i < loginData?.length; i++) {
      let { answer } = loginData[i];
      if (loginData[i]?.type === "email_phone") {
        if (isValidPhone(answer?.value)) {
          let idx = updatedUserDetails?.findIndex(
            (item) => item?.key === "phone"
          );
          if (idx !== -1) {
            updatedUserDetails[idx].answer = answer;
            updatedUserDetails[idx].disabled = true;
          }
        }
        if (isValidEmail(answer?.value)) {
          setHBxEmail(answer?.value);
          let idx = updatedUserDetails?.findIndex(
            (item) => item?.key === "email"
          );
          if (idx !== -1) {
            updatedUserDetails[idx].answer = answer;
            updatedUserDetails[idx].disabled = true;
          }
        }
      }
      if (loginData[i]?.type === "password") {
        let idx = updatedUserDetails?.findIndex(
          (item) => item?.key === "password"
        );
        if (idx !== -1) {
          updatedUserDetails[idx].answer = answer;
          updatedUserDetails[idx].disabled = true;
        }
      }
    }
    setComponents("loginDetails");
    setUserDetailsData(updatedUserDetails);
  }
  function isValidEmail(email) {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  }
  function isValidPhone(phone) {
    const pattern = new RegExp("[0-9]{10}");
    return pattern.test(phone) && phone?.length === 10;
  }
  function isValidPassword(password) {
    const pattern = new RegExp(`^.{7,32}$`);
    return pattern.test(password);
  }
  const setAnswer = (index, question, answer) => {
    let updatedLoginData = JSON.parse(JSON.stringify(loginData));
    if (question?.type === "email_phone") {
      updatedLoginData[index].answer = answer;
      if (isValidPhone(answer?.value) || isValidEmail(answer?.value)) {
        updatedLoginData[index].formValidation = true;
      } else {
        updatedLoginData[index].formValidation = false;
      }
    }
    if (question?.type === "password") {
      updatedLoginData[index].answer = answer;
      if (isValidPassword(answer?.value)) {
        updatedLoginData[index].formValidation = true;
      } else {
        updatedLoginData[index].formValidation = false;
      }
    }
    if (question?.type === "singleCheck") {
      updatedLoginData[index].answer = answer;
      if (answer?.value?.includes(question?.key)) {
        updatedLoginData[index].formValidation = true;
      } else {
        updatedLoginData[index].formValidation = false;
      }
    }
    setLoginData(updatedLoginData);
    let showFlag = true;
    for (let i = 0; i < updatedLoginData?.length; i++) {
      if (!(updatedLoginData[i].formValidation && updatedLoginData[i]?.answer && updatedLoginData[i].answer?.value) ) showFlag = false;
    }
    setNextButtonActive(showFlag);
  };
  return (
    <div className="h-dvh m-auto pt-10 pb-5 px-5 login_page">
      <div className="w-full m-auto">
        <div className="pt-2 text-3xl font_georgia">Sign In or Sign Up</div>
        {loginData && loginData?.length ? (
          <>
            {loginData?.map((ques, index) => {
              return (
                <>
                  {ques?.type === "email_phone" && (
                    <div className="pt-4">
                      <EmailPhone
                        data={ques}
                        formValidation={ques?.formValidation}
                        setAnswer={(ques, ans) => setAnswer(index, ques, ans)}
                      />
                    </div>
                  )}
                  {ques?.type === "password" && (
                    <div className="pt-4">
                      <Password
                        data={ques}
                        formValidation={ques?.formValidation}
                        setAnswer={(ques, ans) => setAnswer(index, ques, ans)}
                      />
                    </div>
                  )}
                  {ques?.type === "singleCheck" && (
                    <div className="pt-4">
                      <SingleCheckBox
                        data={ques}
                        tncCheckbox={true}
                        setAnswer={(ques, ans) => setAnswer(index, ques, ans)}
                      />
                    </div>
                  )}
                </>
              );
            })}
          </>
        ) : (
          <></>
        )}

        <div className="pt-12">
          {isNextButtonActive ? (
            <button
              onClick={handleClickForLogin}
              className="primary_button primary_large_button"
            >
              Verify
            </button>
          ) : (
            <button className="primary_button primary_large_button primary_large_button-inactive">
              Verify
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
export default LoginPage;
