import { useState, useEffect, useContext } from "react";
import "./common.scss";
import FormRendererContext from "../../services/context/FormRendererContext";
import CustomTooltip from "./ToolTip";

const SingleCheckBox = ({
  data,
  setAnswer,
  formValidation,
  tncCheckbox = false,
}) => {
  const [selectedList, setSelectedList] = useState([]);
  const [validForm, setFormValidation] = useState(formValidation);
  const { answerData } = useContext(FormRendererContext);

  const handleCheckboxClick = (value) => {
    if (selectedList?.includes(value)) {
      let optionsData = [...selectedList];
      optionsData.splice(optionsData.indexOf(value), 1);
      setSelectedList(optionsData);
      setAnswer(data, { value: optionsData });
      setFormValidation(true);
      // setAnswer({ question: data.label, answer: optionsData });
    } else {
      setSelectedList([...selectedList, value]);
      setAnswer(data, { value: [...selectedList, value] });
      setFormValidation(true);
      // setAnswer({ question: data.label, answer: [...selectedList, value] });
    }
  };

  useEffect(() => {
    setFormValidation(formValidation);
    if (data?.answer) {
      setSelectedList(data.answer?.value);
    }
    if (answerData?.[data?.key]) {
      setSelectedList(answerData?.[data?.key]?.value);
    }
  }, []);

  return (
    <div className=" w-full single-checkbox">
      <form>
        <div className="checkbox-wrapper-4 radio-common">
          <input
            className="inp-cbx"
            name={data.key}
            value={data.key}
            checked={selectedList?.includes(data.key)}
            onChange={() => handleCheckboxClick(data.key)}
            id={data.key + "" + data.key}
            key={"index"}
            type="checkbox"
          />
          <label
            className={tncCheckbox ? "cbx inline-block" : "cbx cbx-flex"}
            htmlFor={data.key + "" + data.key}
          >
            <span className="mr-1 span-first">
              <svg width="12px" height="10px">
                <use xlinkHref={`#check${data.key + "" + data.key}`}></use>
              </svg>
            </span>
            <span>
              {tncCheckbox ? (
                <>
                  {" "}
                  {data?.label?.map((textObj) => {
                    return (
                      <>
                        <span className={textObj?.class}>
                          &nbsp;{textObj?.text}
                        </span>
                      </>
                    );
                  })}
                </>
              ) : (
                <div className="flex flex-col align-center">
                  <div className="form-title cbx-label">
                    {data.label}
                    {data?.tooltip && (
                      <div className="inline text-center px-1 ">
                        <CustomTooltip tooltipText={data.tooltip} width="600px" >
                        </CustomTooltip>
                      </div>
                    )}
                  </div>
                  {data?.description && (
                    <div className="text-xs text-gray-500 leading-relaxed mt-1">
                      {data.description}
                    </div>
                  )}
                </div>
              )}
            </span>
          </label>
          {/* {data?.description && (
            <div className="text-sm text-gray-600 leading-relaxed pl-5">
              {data.description}
            </div>
          )} */}
          <svg className="inline-svg">
            <symbol id={`check${data.key + "" + data.key}`} viewBox="0 0 12 10">
              <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
            </symbol>
          </svg>
        </div>
      </form>
    </div>
  );
};
export default SingleCheckBox;
