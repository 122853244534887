import { useState, useEffect } from "react";
import "./common.scss";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const DateInput = ({ data, setAnswer, formValidation = true, formClass }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [validForm, setFormValidation] = useState(formValidation);

  useEffect(() => {
    setFormValidation(formValidation);
    if (data?.answer && data.answer?.value !== "") {
      if (
        data.answer?.value &&
        typeof data.answer.value === "object" &&
        data.answer?.value?.$isDayjsObject
      ) {
        setSelectedOption(dayjs(data.answer?.value?.$d));
        setFormValidation(true);
      } else {
        setSelectedOption(data.answer?.value);
        setFormValidation(true);
      }
    }
  }, [data, formValidation]);

  const handleDateChange = (date) => {
    setSelectedOption(date);
    setAnswer(data, { value: date });
    date && setFormValidation(true);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className={`w-full ${formClass}`}>
        <div className="form-title pt-3">
          {data.label}
          {data?.validate?.required ? (
            <span className={!validForm ? "text-red-700" : "text-gray-600"}>
              {" "}
              *
            </span>
          ) : (
            <></>
          )}
        </div>
        {  console.log("selectedOption", selectedOption)
        }
        <form className="flex-grow">
          <div className="radio-common date-picker my-2">
            <DatePicker
              // label="Controlled field"
              disableFuture={data?.maxDate === "today"}
              value={selectedOption}
              onChange={(newValue) => {
                handleDateChange(newValue);
              }}
              className="text-field text-input"
            />

            {/* <input
              type="date"
              value={selectedOption}
              
              pattern="\d{4}-\d{2}-\d{2}"
              onChange={(e) => {
                setSelectedOption(e.target.value);
                setAnswer(data, {value: e.target.value});
                setFormValidation(true);
              }}
              className="text-field text-input"
            /> */}
          </div>
        </form>
        {data?.validate?.required && !validForm ? (
          <span className="text-red-700 font-light text-xs">
            {data?.invalidMessage || "Required Field"}
          </span>
        ) : (
          <></>
        )}
      </div>
    </LocalizationProvider>
  );
};
export default DateInput;
