import React, { useEffect, useState, useContext } from "react";
import "./scheduler.scss";
import axios from "axios";
import FormRendererContext from "../../services/context/FormRendererContext";
import moment from "moment";
import Loader from "../../components/common/loader";
import ScheduleAppointment from "../scheduleAppointment";
import TextField from "../../components/common/TextField";
import DateInput from "../../components/common/DateInput";
import Email from "../../components/common/Email";
import ModalBox from "../../components/common/modal";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  // bgcolor: 'background.paper hjdsgbfuhds kjdhiudh',
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const steps = ["Location", "Screening", "Schedule", "Your Info"];
const timezoneMapper = {
  est: -5,
  cst: -6,
  mst: -7,
  pst: -8,
  akst: -9,
  hst: -10,
};

const SchedulerWidgetForSynaptrxDemo = ({
  widget,
  handleNextScreenPage = () => {},
  handleBackScreenPage = () => {},
  setAnswerForWidget = () => {},
}) => {
  const { answerData } = useContext(FormRendererContext);

  const [schedulerId, setSchedulerId] = useState(widget.id);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(
    answerData?.patientStateForScheduler?.value
  );
  const [reasons, setReasons] = useState([]);
  const [selectedReason, setSelectedReason] = useState(null);
  const [appointmentSlot, setAppointmentSlot] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [providerDetails, setProviderDetails] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [department_id_local, setDepartment_id_local] = useState(150);

  const [firstName, setFirst] = useState("");
  const [lastName, setLast] = useState("");
  const [dob, setDateOfBirth] = useState(null);
  const [email, setEmail] = useState("");

  const {
    scheduler_id,
    setScheduler_id,
    provider_id,
    setProvider_id,
    reason_id,
    setReason_id,
    appointment_id,
    setAppointment_id,
    patient_id,
    setPatient_id,
    HBxFirstName,
    HBxLastName,
    HBxEmail,
    HBxDob,
    HBxReasonForVisit,
    department_id,
    setDepartment_id,
    journeyId,
    amount,
    appointmentSlotData,
    patientTZ,
  } = useContext(FormRendererContext);

  const fetchProviderDetails = async (schedulerId, providerId) => {
    // setIsLoading(true);
    let API_URL = `${
      process.env?.REACT_APP_SCHEDULER_URL ||
      "https://apps-sandbox.healthblox.care/scheduler"
    }/api/v1/providers/${schedulerId}/id/${providerId}`;
    try {
      const response = await axios.get(API_URL);
      const { HBx } = response?.data;
      // setIsLoading(false);
      return HBx;
    } catch (error) {
      console.log(error);
      // setIsLoading(false);
      return null;
    }
  };

  const fetchAllProviders = async (departmentId) => {
    setIsLoading(true);
    let API_URL = `${
      process.env?.REACT_APP_FOUNTAIN_HEAD_URL ||
      "https://fountainhead-sandbox.healthblox.care"
    }/api/v1/pilots/providers/${departmentId}`;
    try {
      const response = await axios.get(API_URL);
      // setIsLoading(false);
      return response?.data;
    } catch (error) {
      console.log(error);
      // setIsLoading(false);
      return null;
    }
  };

  const handleReasonChange = async (val) => {
    setIsLoading(true);
    setSelectedReason(val);
    setReason_id(val);
    let providersList = [];

    let { providers } = await fetchAllProviders(selectedDepartment);

    let providerlist = [...providers];
    // let providerlist = ["111", "27", "108", "109", "72", "87", "88", "67"];
    for (let i = 0; i < providerlist.length; i++) {
      let providerDetail = await fetchProviderDetails(
        schedulerId,
        providerlist[i]
      );
      providersList.push({ providerId: providerlist[i], providerDetail });
    }
    setProviderDetails(providersList);
    setIsLoading(false);
  };

  useEffect(() => {
    if (widget?.id) {
      setSchedulerId(widget.id);
      setScheduler_id(widget.id);
    }
    if (HBxDob) {
      setDateOfBirth(HBxDob);
    }
    if (HBxEmail) {
      setEmail(HBxEmail);
    }
    if (HBxFirstName) {
      setFirst(HBxFirstName);
    }
    if (HBxLastName) {
      setLast(HBxLastName);
    }
    if (answerData?.reasonForVisitForScheduler) {
      let reasonId = answerData?.reasonForVisitForScheduler?.value
        ? parseInt(answerData?.reasonForVisitForScheduler?.value, 10)
        : 1301;
      handleReasonChange(reasonId);
    }
    if (answerData?.patientStateForScheduler?.value) {
      setSelectedDepartment(answerData?.patientStateForScheduler?.value);
      setDepartment_id(150);
      setDepartment_id_local(150);
    }
  }, []);

  const handleBackClick = () => {
    if (currentStep === 0) {
      handleBackScreenPage();
    }
    if (currentStep === 1) {
      setCurrentStep((prev) => prev - 1);
    }
    if (currentStep === 2) {
      setCurrentStep((prev) => prev - 1);
    }
    if (currentStep === 3) {
      setCurrentStep((prev) => prev - 1);
    }
  };
  async function checkForUsers() {
    let API_URL = `${
      process.env?.REACT_APP_SCHEDULER_URL ||
      "https://apps-sandbox.healthblox.care/scheduler"
    }/api/v1/patients/${schedulerId}?firstName=${firstName}&lastName=${lastName}&DOB=${encodeURIComponent(
      dateFormatter(dob)
    )}&email=${encodeURIComponent(email)}`;
    try {
      const response = await axios.get(API_URL);
      const { HBx } = response.data;
      const patientData = HBx[0]?.HBx;
      if (patientData && patientData?.patientId) return patientData.patientId;
      return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
  async function createAppointment(patientID, patientInfoBody) {
    setIsLoading(true);
    let API_URL = `${
      process.env?.REACT_APP_SCHEDULER_URL ||
      "https://apps-sandbox.healthblox.care/scheduler"
    }/api/v1/appointments/${schedulerId}`;

    const referralSource =
      journeyId === "66fe0af45b3a1419fb71a944"
        ? "amerigas"
        : journeyId === "66ff54a25b3a1419fb71ad68"
        ? "cpapcom"
        : journeyId === "66ff6b715b3a1419fb71b380"
        ? "default"
        : "";
    const bookingNote = {
      referralSource: referralSource,
    };
    const sanitisedPatientInfoBody = structuredClone(patientInfoBody);
    const sanitisedSlot = structuredClone(appointmentSlotData?.slot);
    try {
      const response = await axios.put(API_URL, {
        appointmentId: appointment_id,
        patientId: patientID,
        bookingNote: JSON.stringify(bookingNote),
        departmentId: department_id_local,
        reasonId: reason_id,
        meta: {
          referralSource,
          amount,
          firstName,
          email,
          providerTZ: appointmentSlotData?.providerTimeZoneOffset || null,
          patientTZ: patientTZ,
          slotData: sanitisedSlot,
          patientInfo: sanitisedPatientInfoBody,
        },
      });
      const { HBx } = response.data;
      setAnswerForWidget(HBx);
      setCurrentStep((prev) => prev + 1);
      setOpenModal(true);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }
  const handleNextClick = async () => {
    if (currentStep === 1) {
      if (firstName && lastName && dob && email) {
        setIsLoading(true);

        let patientInfoBody = {
          address: {
            streetAddress: answerData?.address1?.value || "",
            ZIP: answerData?.zipCode?.value || "",
            city: answerData?.city?.value || "",
            state: answerData?.patientStateForScheduler?.value || "",
            country: answerData?.country?.value || "",
          },
          email: [email],
          phoneNumber: {
            mobile: answerData?.mobilePhone?.value || "",
          },
          consentToCall: answerData?.consentToCall?.value?.length
            ? true
            : false,
          consentToText: answerData?.iAuthorizeHBxSleepToSendTextReminders
            ?.value?.length
            ? true
            : false,
          sex:
            answerData?.gender?.value === "female"
              ? "F"
              : answerData?.gender?.value === "male"
              ? "M"
              : "",
        };

        let patientID = await checkForUsers();
        if (patientID) {
          setPatient_id(patientID);
          let API_URL = `${
            process.env?.REACT_APP_SCHEDULER_URL ||
            "https://apps-sandbox.healthblox.care/scheduler"
          }/api/v1/patients/${schedulerId}`;

          const response = await axios.put(API_URL, {
            patientId: patientID,
            ...patientInfoBody,
          });

          await createAppointment(patientID, patientInfoBody);
        } else {
          let API_URL = `${
            process.env?.REACT_APP_SCHEDULER_URL ||
            "https://apps-sandbox.healthblox.care/scheduler"
          }/api/v1/patients/${schedulerId}`;
          try {
            const response = await axios.post(API_URL, {
              firstName: firstName,
              lastName: lastName,
              DOB: dateFormatter(dob),
              departmentId: department_id_local,
              email: [email],
              ...patientInfoBody,
            });
            const { HBx } = response?.data;
            const { patientId } = HBx;
            setPatient_id(patientId);
            await createAppointment(patientId, patientInfoBody);
          } catch (error) {
            console.log(error);
            setIsLoading(false);
          }
        }
      }
    }
    if (currentStep === 0) {
      setCurrentStep((prev) => prev + 1);
    }
  };
  function setFirstName(name, data) {
    setFirst(data.value);
  }
  function setLastName(name, data) {
    setLast(data.value);
  }
  function setDOB(name, data) {
    setDateOfBirth(data.value);
  }
  function dateFormatter(date) {
    const formattedDate = moment(date?.$d).format("MM/DD/YYYY");
    return formattedDate;
  }
  function setEmailId(name, data) {
    setEmail(data.value);
  }
  return (
    <div className="">
      {!isLoading ? (
        <>
          {currentStep === 0 && (
            <>
              {providerDetails && providerDetails.length ? (
                <div>
                  <ScheduleAppointment
                    data={providerDetails}
                    activateNextClick={handleNextClick}
                  />
                </div>
              ) : (
                <div className="text-xl text-center">
                  <div className="px-10 inline-block py-3 rounded bg-gray-200 my-5">
                    No providers found for this state
                  </div>
                </div>
              )}
            </>
          )}
          {currentStep === 1 && (
            <div>
              <div className="content-width p-5">
                <div className="form-width mx-auto">
                  <TextField
                    data={{ label: "First Name", answer: { value: firstName } }}
                    setAnswer={setFirstName}
                  />
                  <TextField
                    data={{ label: "Last Name", answer: { value: lastName } }}
                    setAnswer={setLastName}
                  />
                  <DateInput
                    data={{ label: "DOB", answer: { value: dob } }}
                    setAnswer={setDOB}
                  />
                  <Email
                    data={{ label: "Email", answer: { value: email } }}
                    setAnswer={setEmailId}
                  />
                </div>
              </div>
            </div>
          )}
          {currentStep === 2 && openModal && (
            <>
              <ModalBox
                openModal={true}
                leftButtonText={"Close"}
                rightButtonText={"Continue"}
                rightButtonCallback={() => {
                  setOpenModal(false);
                  handleNextScreenPage();
                }}
                title={"Thank You !"}
                SubTitle={"Your Appointment has been Scheduled successfully"}
                hideClose={true}
              />
            </>
          )}
        </>
      ) : (
        <div className="mt-20">
          <Loader />
        </div>
      )}
      <div className="bottom-bar-shadow bottom-bar-scheduler-shadow">
        <div className="flex justify-between pt-3 m-auto bottom-bar-scheduler w-full">
          <button onClick={handleBackClick} className="text_button">
            Back
          </button>
          <div className={currentStep === 0 ? "hidden" : ""}>
            <div onClick={handleNextClick} className="primary_button">
              {currentStep === 1 ? "Schedule Appointment" : "Continue"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SchedulerWidgetForSynaptrxDemo;
