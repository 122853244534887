import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "./common.scss";
import Loader from "./loader";

const DropDownWithSearchForAllergy = ({
  label,
  placeholder = "Search...",
  disabled = false,
  required = false,
  invalidMessage = "Required Field",
  fetchOptions,
  onOptionSelect,
  selectedOptionData,
  formClass = "",
  suffix = "",
  displayKey
}) => {
  const [selectedOption, setSelectedOption] = useState(selectedOptionData?.label || "");
  const [showDropdown, setShowDropdown] = useState(false);
  const [options, setOptions] = useState([]);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [validForm, setValidForm] = useState(true);
  const dropdownRef = useRef(null);

  const throttle = (func, limit) => {
    let inThrottle;
    return function (...args) {
      if (!inThrottle) {
        func(...args);
        inThrottle = true;
        setTimeout(() => (inThrottle = false), limit);
      }
    };
  };

  // Throttled function to fetch options
  const fetchThrottledOptions = throttle((query) => {
    if (query.length > 2) {
      setLoading(true);
      fetchOptions(query).then((result) => {
        setOptions(result || []);
        setLoading(false);
      });
    } else {
      setOptions([]);
    }
  }, 500);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleOptionSelect = (option) => {
    setSelectedOption(option?.[displayKey]);
    setQuery(option?.[displayKey]);
    onOptionSelect(option); // Customizable option selection handler
    setValidForm(true);
    setShowDropdown(false);
  };

  useEffect(() => {
    if (selectedOptionData) {
      setSelectedOption(selectedOptionData?.label);
      setQuery(selectedOptionData?.label);
    } else {
      setSelectedOption("");
      setQuery("");
    }
  }, [selectedOptionData]);

  return (
    <div className={`w-full ${formClass}`} ref={dropdownRef}>
      <div className="form-title pt-3">
        {label}
        {required && (
          <span className={!validForm ? "text-red-700" : "text-gray-600"}>*</span>
        )}
      </div>
      <form className="flex-grow">
        <div className="radio-common relative">
          <label className="relative">
            <input
              type="text"
              disabled={disabled}
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
                fetchThrottledOptions(e.target.value);
                setShowDropdown(true);
              }}
              placeholder={placeholder}
              className="text-field text-input"
            />
            {suffix && (
              <div
                className="editable_div absolute w-full"
                onClick={() => document.getElementById(label + "editable")?.focus()}
              >
                <div
                  contentEditable="false"
                  className="editable_span inline-block pr-1"
                  id={label + "editable"}
                >
                  {selectedOption}
                </div>
                <div contentEditable="false" className="non_editable_span inline-block">
                  {suffix}
                </div>
              </div>
            )}
            {showDropdown && (
              <div
                className="absolute w-full border rounded-lg mt-3 bg-white overflow-y-auto z-10"
                style={{ maxHeight: "260px", minHeight: "64px", borderColor: "#dcdfe4" }}
              >
                {loading ? (
                  <div className="text-center py-2">
                    <Loader />
                  </div>
                ) : (
                  options.map((option, index) => (
                    <div
                      key={index}
                      className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                      onClick={() => handleOptionSelect(option)}
                    >
                      {option?.[displayKey]}
                    </div>
                  ))
                )}
                {options.length === 0 && !loading && (
                  <div className="text-center py-2 text-gray-500">No options found</div>
                )}
              </div>
            )}
          </label>
        </div>
      </form>
      {required && !validForm && (
        <span className="text-red-700 font-light text-xs">{invalidMessage}</span>
      )}
    </div>
  );
};

DropDownWithSearchForAllergy.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  invalidMessage: PropTypes.string,
  fetchOptions: PropTypes.func.isRequired,
  onOptionSelect: PropTypes.func.isRequired,
  selectedOptionData: PropTypes.shape({
    name: PropTypes.string,
  }),
  formClass: PropTypes.string,
  suffix: PropTypes.string,
};

export default DropDownWithSearchForAllergy;
