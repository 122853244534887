import React, { useState, useEffect, useContext } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import axios from "axios";
import FormRendererContext from "../../services/context/FormRendererContext";
import Email from "../../components/common/Email";
import Loader from "../../components/common/loader";

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export default function StripeCheckout({
  org_id,
  amount,
  widget,
  handleNextScreenPage = () => {},
  handleBackScreenPage = () => {},
  setAnswerForWidget = () => {},
}) {
  const [clientSecret, setClientSecret] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const { connectID, save_details, paymentType } = widget;
  const { HBxEmail, setAmount } = useContext(FormRendererContext);
  const [email, setEmail] = useState(HBxEmail || 'dummy@healthblox.care');
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY, {
    stripeAccount: connectID,
  });

  const createIntent = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PAYMENT_SERVER}/payments/setup-intent`,
        {
          org_id: org_id,
          email: email,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const data = response.data;
      setClientSecret(data.clientSecret);
      setIsLoader(false);
    } catch (error) {
      console.error(error);
    }
  };

  // const createIntent = async () => {
  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_PAYMENT_SERVER}/payments/payment-intent`,
  //       {
  //         amount: amount,
  //         org_id: org_id,
  //       },
  //       {
  //         headers: { "Content-Type": "application/json" },
  //       }
  //     );
  //     const data = response.data;
  //     setClientSecret(data.clientSecret);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const createPaymentIntent = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PAYMENT_SERVER}/payments/payment-intent`,
        {
          amount: amount,
          org_id: org_id,
          email: email,
          save_details: save_details,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const data = response.data;
      setClientSecret(data.clientSecret);
      setIsLoader(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (email && paymentType === "payment") {
      setIsLoader(true);
      createPaymentIntent();
    } else if (email && paymentType === "setup") {
      setIsLoader(true);
      createIntent();
    }
    setAmount(amount)
  }, [widget]);

  const options = {
    clientSecret,
  };

  return (
    <>
      {isLoader ? (
        <div className="my-10">
        <Loader />
        </div>
      ) : (
        <div>
          {clientSecret && (
            <Elements options={options} stripe={stripePromise}>
              <div style={{ margin: "24px" }}>
                <CheckoutForm
                  type={paymentType}
                  handleNextScreenPage={handleNextScreenPage}
                  handleBackScreenPage={handleBackScreenPage}
                  setAnswerForWidget={setAnswerForWidget}
                  amount={amount}
                />
              </div>
            </Elements>
          )}
        </div>
      )}
    </>
  );
}
