import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { useState } from "react";
import { createTheme } from "@mui/material/styles";
const {
  InputLabel,
  OutlinedInput,
  FormControlLabel,
  Checkbox,
  IconButton,
  InputAdornment,
  FormHelperText,
  ThemeProvider,
} = require("@mui/material");

export const theme = createTheme({
  // palette: {
  //   primary: {
  //     main: "var(--primary)",
  //     light: "#CF74471A",
  //     dark: "var(--primary)",
  //   },
  // },
});
const CustomInput = ({
  labelText,
  helperText,
  password = false,
  sx,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(password ? false : true);
  let password_settings = <></>;
  if (password) {
    password_settings = (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => {
            setShowPassword((show) => !show);
          }}
        >
          {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
        </IconButton>
      </InputAdornment>
    );
  }
  return (
    <>
      <ThemeProvider theme={theme}>
        <InputLabel sx={{ mt: "8px" }}>{labelText}</InputLabel>
        <OutlinedInput
          inputProps={{
            style: {
              padding: 8,
              borderColor: "var(--primary) ",
            },
          }}
          sx={{
            ...sx,
            mt: "8px",
            px: "12px",
            borderRadius: 2,
            borderColor: "#00000001",

            // "&:selection": {
            //   borderColor: "var(--primary) !important",
            //   backgroundColor: "CF7447", // Change border color when input is focused
            // },
          }}
          type={showPassword ? "text" : "password"}
          endAdornment={password_settings}
          {...rest}
        />
        {helperText && (
          <FormHelperText sx={{ color: "#D32F2F", pl: 1 }}>
            {helperText}
          </FormHelperText>
        )}
      </ThemeProvider>
    </>
  );
};

export default CustomInput;

export const CustomFormControlLabel = ({ labelText, ...rest }) => {
  return (
    <ThemeProvider theme={theme}>
      <FormControlLabel
        control={
          <Checkbox
            sx={{
              ":checked": {
                color: "var(--primary) ",
                backgroundColor: "var(--primary) ",
              },
              ":after": {
                color: "var(--primary) ",
                backgroundColor: "var(--primary) ",
              },
            }}
            {...rest}
          />
        }
        label={<InputLabel>{labelText}</InputLabel>}
      />
    </ThemeProvider>
  );
};
