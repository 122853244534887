import "./common.scss";

import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

let style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: 400,
};

function ModalBox({
  openModal,
  leftButtonText,
  rightButtonText,
  title,
  SubTitle,
  showButtons = true,
  rightButtonCallback,
  hideClose = false,
  showIcon = true,
  maxWidth = 400
}) {
  const [open, setOpen] = React.useState(openModal);
  style.maxWidth = maxWidth
  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };
  return (
    <div>
      <div className="p-5 w-full">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="box-modal">
            {showButtons && !hideClose && (
              <div className="flex justify-end">
                <CloseIcon
                  fontSize="small"
                  className="cursor-pointer text-gray-700"
                  onClick={handleClose}
                />
              </div>
            )}
            <div className="modal-content flex flex-col gap-4 justify-center p-4">
              {showIcon && (
                <CheckIcon
                  className="text-green-500 bg-green-100 p-1 rounded-2xl mx-auto"
                  fontSize="large"
                  sx={{ fontWeight: "400" }}
                />
              )}
              <div className="modal-title mx-auto">{title}</div>
              <div className="modal-subtitle mx-auto">{SubTitle}</div>
              {showButtons && (
                <div className="flex gap-4">
                  {!hideClose && (
                    <div
                      className="text-center grow modal-button modal-button-left"
                      onClick={handleClose}
                    >
                      {leftButtonText}
                    </div>
                  )}
                  <div
                    className="text-center grow modal-button modal-button-right"
                    onClick={(e) => {
                      if (rightButtonCallback) {
                        rightButtonCallback(e);
                      } else handleClose();
                    }}
                  >
                    {rightButtonText}
                  </div>
                </div>
              )}
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
}
export default ModalBox;
