// ProgressTracker.js

import React from "react";
import "./ProgressTracker.scss";


import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const ProgressTracker = ({ steps, currentStep  }) => {
  return (
    <div className="progress-tracker">
      <Box sx={{ width: '100%' }}>
      <Stepper activeStep={currentStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
    </div>
  );
}

export default ProgressTracker;
