import { theme } from "./CustomInput";

const { Button, Typography, ThemeProvider } = require("@mui/material");
const CustomButton = ({ children, color, variant, font, ...rest }) => {
  return (
    <ThemeProvider theme={theme}>
      <Button variant={variant ?? "contained"} {...rest}>
        {children}
      </Button>
    </ThemeProvider>
  );
};

export default CustomButton;

export const CustomButtonIcon = ({ logo, variant, text, ...rest }) => {
  return (
    <ThemeProvider theme={theme}>
      <Button
        variant={variant ?? "outlined"}
        p={"5px 15px 5px 15px"}
        endIcon={<img src={logo} alt="logo" style={{ height: 20 }} />}
        {...rest}
      >
        <Typography variant="body1">{text}</Typography>
      </Button>
    </ThemeProvider>
  );
};

export const CustomTextButton = ({ children, variant, sx, ...rest }) => {
  return (
    <Typography
      variant={variant ?? "body2"}
      sx={{ ...sx, cursor: "pointer" }}
      {...rest}
    >
      {children}
    </Typography>
  );
};
