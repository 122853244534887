import React, { useContext, useState } from "react";

import "./LoginDetails.scss";

import FormRendererContext from "../../services/context/FormRendererContext";
import TextField from "../../components/common/TextField";
import DateInput from "../../components/common/DateInput";
import Password from "../../components/common/Password";

const LoginDetailsPage = () => {
  const {
    setComponents,
    userDetailsData,
    setUserDetailsData,
    setHBxFirstName,
    setHBxLastName,
    setHBxEmail,
    setHBxDob,
  } = useContext(FormRendererContext);

  const [isNextButtonActive, setNextButtonActive] = useState(false);

  function handleClickForLoginDetails() {

    setComponents("journey");
  }
  function isValidEmail(email) {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  }
  function isValidPhone(phone) {
    const pattern = new RegExp("[0-9]{10}");
    return pattern.test(phone) && phone?.length === 10;
  }
  const setAnswer = (index, question, answer) => {
    let updatedUserData = JSON.parse(JSON.stringify(userDetailsData));
    if (question?.type === "textField") {
      if (question?.key === "firstName" || question?.key === "lastName") {
        updatedUserData[index].answer.value = answer.value?.toUpperCase();
        question?.key === "firstName" && setHBxFirstName(answer.value?.toUpperCase())
        question?.key === "lastName" && setHBxLastName(answer.value?.toUpperCase())
      } else updatedUserData[index].answer = answer;

      if (
        answer &&
        answer?.value &&
        (answer.value !== "" || answer.value !== null)
      ) {
        updatedUserData[index].formValidation = true;
      } else {
        updatedUserData[index].formValidation = false;
      }
      if (question?.key === "email") {
        setHBxEmail(answer?.value)
        if (isValidEmail(answer?.value)) {
          updatedUserData[index].formValidation = true;
        } else {
          updatedUserData[index].formValidation = false;
        }
      }
      if (question?.key === "phone") {
        if (isValidPhone(answer?.value)) {
          updatedUserData[index].formValidation = true;
        } else {
          updatedUserData[index].formValidation = false;
        }
      }
    }
    if (question?.type === "dob") {
      updatedUserData[index].answer = answer;
      setHBxDob(answer?.value)
      if (answer && answer?.value && answer.value?.$d) {
        let birth_date = new Date(answer.value.$d);
        let todays_date = new Date();
        if (todays_date - birth_date > 565633905872) {
          updatedUserData[index].formValidation = true;
        } else {
          updatedUserData[index].formValidation = false;
        }
      } else {
        updatedUserData[index].formValidation = false;
      }
    }
    setUserDetailsData(updatedUserData);
    let showFlag = true;
    for (let i = 0; i < updatedUserData?.length; i++) {
      if (
        !(
          updatedUserData[i].formValidation &&
          updatedUserData[i]?.answer &&
          updatedUserData[i].answer?.value
        )
      )
        showFlag = false;
    }
    setNextButtonActive(showFlag);
  };
  return (
    <div className="min-h-dvh m-auto pt-10 pb-5 px-5 login-details-page">
      <div className="w-full m-auto">
        <div className="pt-2 text-3xl font_georgia">
          A little more to help us identify you...
          {/* Tell us about yourself... */}
        </div>
        <div className="pt-2 text-center text-gray-700 text-sm font_georgia">
          All your data is encrypted + HIPAA compliant
        </div>
        {userDetailsData && userDetailsData?.length ? (
          <>
            {userDetailsData?.map((ques, index) => {
              return (
                <>
                  {ques?.type === "textField" && (
                    <div className="pt-4">
                      <TextField
                        data={ques}
                        formValidation={ques?.formValidation}
                        setAnswer={(ques, ans) => setAnswer(index, ques, ans)}
                      />
                    </div>
                  )}
                  {ques?.type === "password" && (
                    <div className="pt-4">
                      <Password
                        data={ques}
                        formValidation={ques?.formValidation}
                        setAnswer={(ques, ans) => setAnswer(index, ques, ans)}
                      />
                    </div>
                  )}
                  {ques?.type === "dob" && (
                    <div className="pt-4">
                      <DateInput
                        data={ques}
                        formValidation={ques?.formValidation}
                        setAnswer={(ques, ans) => setAnswer(index, ques, ans)}
                      />
                    </div>
                  )}
                </>
              );
            })}
          </>
        ) : (
          <></>
        )}

        <div className="pt-12">
          {isNextButtonActive ? (
            <button
              onClick={handleClickForLoginDetails}
              className="primary_button primary_large_button"
            >
              Enter
            </button>
          ) : (
            <button className="primary_button primary_large_button primary_large_button-inactive">
              Enter
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
export default LoginDetailsPage;
