import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Button } from "@mui/material";
import { FiChevronsLeft } from "react-icons/fi";
import ModalBox from "../../components/common/modal";
import Loader from "../../components/common/loader";
import "./payment.scss";

export default function CheckoutForm({
  type,
  handleNextScreenPage,
  handleBackScreenPage,
  setAnswerForWidget,
  amount,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [disable, setDisable] = useState(false);
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (!stripe) return;
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    if (!clientSecret) return;
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      setMessage(
        paymentIntent.status === "succeeded"
          ? "Your payment succeeded"
          : "Unexpected error occurred"
      );
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return;
    setIsLoading(true);
    if (type === "setup") {
      const { error } = await stripe.confirmSetup({
        elements,
        // confirmParams: {
        //   return_url: `${process.env.REACT_APP_FRONTEND_URL}/success`,
        // },
        redirect: "if_required",
      });
      if (error) {
        setMessage(error.message);
      } else {
        // setMessage("Your Payment setup is done !");
        // setDisable(true);
        // setOpenModal(true);
        setOpenModal(false);
        handleNextScreenPage();
        setAnswerForWidget();
      }
    } else {
      const { error } = await stripe.confirmPayment({
        elements,
        // confirmParams: {
        //   return_url: `${process.env.REACT_APP_FRONTEND_URL}/success`,
        // },
        redirect: "if_required",
      });
      if (error) {
        setMessage(error?.message);
      } else {
        // setMessage("Your Payment is confirmed");
        // setDisable(true);
        // setOpenModal(true);
        setOpenModal(false);
        handleNextScreenPage();
        setAnswerForWidget();
      }
    }
    setIsLoading(false);
  };

  return (
    <>
      {/* {isLoading && (
        <div className=" flex flex col min-h-96 justify-center items-center">
          <Loader />
        </div>
      )} */}
      <div>
        <form onSubmit={handleSubmit}>
          <p className="text-gray-800 mb-4 form-title">
            Complete your payment of{" "}
            <span className=" text-lg font-bold">${amount}</span>
          </p>
          <PaymentElement />
          <div className="flex justify-between py-8">
            <button
              onClick={() => handleBackScreenPage()}
              className="text_button"
            >
              Back
            </button>
            {isLoading ? (
              <div className="dot-loader"></div>
            ) : (
              <button
                onClick={handleSubmit}
                className="primary_button"
                disabled={isLoading || !stripe || !elements || disable}
              >
                {isLoading
                  ? "Loading..."
                  : type === "setup"
                  ? "setup"
                  : "Pay now"}
              </button>
            )}
          </div>

          {/* {message && openModal && (
            <div>
              <ModalBox
                openModal={openModal}
                leftButtonText={"Close"}
                rightButtonText={"Continue"}
                rightButtonCallback={(e) => {
                  e.preventDefault();
                  setOpenModal(false);
                  handleNextScreenPage();
                  setAnswerForWidget();
                }}
                title={"Thank You !"}
                SubTitle={message}
                hideClose={true}
              />
            </div>
          )} */}
        </form>
      </div>
    </>
  );
}
