export const themes = {
  default: {
    cssVariables: {
      buttonBorderRadius: "4px",
      inputFieldBorderColor: "#dcdfe4",
    },
    cssClasses: {},
  },
  themeSynaptrixDemo: {
    cssVariables: {
      buttonBorderRadius: "4px",
      inputFieldBorderColor: "#dcdfe4",
    },
    cssClasses: {},
  },
  themeCancelForAmerigas: {
    cssVariables: {
      buttonBorderRadius: "4px",
      inputFieldBorderColor: "#dcdfe4",
    },
    cssClasses: {},
  },
  themeSynaptrixDemoPartnerTelehealth: {
    cssVariables: {
      buttonBorderRadius: "4px",
      inputFieldBorderColor: "#dcdfe4",
    },
    cssClasses: {},
  },
  themeCancelForPartnerTelehealth: {
    cssVariables: {
      buttonBorderRadius: "4px",
      inputFieldBorderColor: "#dcdfe4",
    },
    cssClasses: {},
  },
  themeSynaptrixDemoForCpap: {
    cssVariables: {
      primary: "rgb(108,189,69)",
      primaryLight: "rgba(108,189,69,0.6)",
      buttonBorderRadius: "20px",
      inputFieldBorderColor: "rgb(108,189,69)",
    },
    cssClasses: {},
  },
  themeCancelForCPAP: {
    cssVariables: {
      primary: "rgb(108,189,69)",
      primaryLight: "rgba(108,189,69,0.6)",
      buttonBorderRadius: "20px",
      inputFieldBorderColor: "rgb(108,189,69)",
    },
    cssClasses: {},
  },
  theme1: {
    cssVariables: {
      primary: "#247bff",
      primaryLight: "rgba(36, 123, 255, 0.6)",
      buttonBorderRadius: "4px",
      inputFieldBorderColor: "#dcdfe4",
    },
    cssClasses: {},
  },
  theme2: {
    cssVariables: {
      primary: "#f79420",
      primaryLight: "rgba(247,148,32,0.6)",
      buttonBorderRadius: "4px",
      inputFieldBorderColor: "#dcdfe4",
      "primary-text-color": "#f79420",
      // primary: "rgb(35, 23, 81)",
      // primaryLight: "rgba(35, 23, 81, 0.6)",
      // "primary-text-color": "rgb(35, 23, 81)",
    },
    cssClasses: {},
  },
  theme3: {
    cssVariables: {
      primary: "#f79420",
      primaryLight: "rgba(247,148,32,0.6)",
      buttonBorderRadius: "4px",
      inputFieldBorderColor: "#dcdfe4",
    },
    cssClasses: {},
  },
  theme4: {
    cssVariables: {
      primary: "rgb(108,189,69)",
      primaryLight: "rgba(108,189,69,0.6)",
      buttonBorderRadius: "20px",
      inputFieldBorderColor: "rgb(108,189,69)",
    },
    cssClasses: {},
  },
};
