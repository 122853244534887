import { tz } from "moment-timezone";
const timezoneMapper = {
  "-5": "America/New_York",
  "-6": "America/Chicago",
  "-7": "America/Denver",
  "-8": "America/Los_Angeles",
  "-9": "America/Anchorage",
  "-10": "Pacific/Honolulu",
};

export const convertTimeWithTimeZone = (
  time,
  dateObj,
  originalTimezone,
  newTimezone
) => {
  if (newTimezone) {
    const date = dateObj.toISOString().split("T")[0]; // Get the date in YYYY-MM-DD format

    // Combine the provided date with the time
    const dateStr = `${date}T${time}:00`; // Assuming date is in "YYYY-MM-DD" format

    // Convert the time from the original timezone to UTC
    const originalTimeInUTC = tz(dateStr, originalTimezone).utc();

    // Convert the UTC time to the new timezone
    const newTime = originalTimeInUTC
      .tz(timezoneMapper[newTimezone])
      .format("HH:mm");

    return newTime;
  }
  return time;
};
