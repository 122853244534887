import axios from "axios";


export const getStandardFormData = async (formId, errorHandlerFunction=(()=>{})) => {
  const API_URL = process.env.REACT_APP_API_URL
  const fetchFormApiUrl = `${API_URL}/forms/user/${formId}`;
  try {
    const response = await axios.get(fetchFormApiUrl);
    return response;
  } catch (e) {
    // console.log(e);
    errorHandlerFunction()
  }
};
export const getJourneyDataForUsers = async (journeyId, errorHandlerFunction=(()=>{})) => {
  const API_URL = process.env.REACT_APP_API_URL
  const fetchJourneyApiUrl = `${API_URL}/journey/user/${journeyId}`;
  try {
    const response = await axios.get(fetchJourneyApiUrl);
    return response;
  } catch (e) {
    // console.log(e);
    errorHandlerFunction()
  }
};

export const getFormDataForUsers = async (formId, errorHandlerFunction=(()=>{})) => {
  const API_URL = process.env.REACT_APP_API_URL
  const fetchFormApiUrl = `${API_URL}/forms/user/${formId}`;
  try {
    const response = await axios.get(fetchFormApiUrl);
    return response;
  } catch (e) {
    // console.log(e);
    errorHandlerFunction()
  }
};

export const newFormSubmit = async (formData, org, errorHandlerFunction=(()=>{})) => {
  const API_URL = process.env.REACT_APP_API_URL
  const formSubmissionUrl = `${API_URL}/formsubmissions`;

  try {
    const response = await axios.post(
      formSubmissionUrl,
      {
        form: formData.formId,
        org, 
        body: {
          form : formData
        }
      },
    );
    return response;
  } catch (e) {
    // console.log(e);
    errorHandlerFunction()
  }
};
