import React, { useContext } from "react";

import "./Landing.scss";
import logo from "../../assets/logo.jpg";
import FormRendererContext from "../../services/context/FormRendererContext";


const LandingPage = () => {
  const {
    setComponents
  } = useContext(FormRendererContext);
  function handleClickForLanding() {
    setComponents('login')
  }
  return (
    <div className="flex flex-col h-dvh m-auto p-5 landing_page">
      <div className="grow content-center m-auto">
        <img src={logo} />
        <div className="pt-2 text-center text-lg">
          <span className="text-gray-500">Sleep </span>
          <span className="font-bold text-gray-500">Well, </span>
          <span className="text-gray-500">Live </span>
          <span className="font-bold text-gray-500">Better </span>
        </div>
      </div>
      <div className="flex-none">
        <button onClick={handleClickForLanding} className="primary_button primary_large_button">
          Enter
        </button>
      </div>
    </div>
  );
};
export default LandingPage;
