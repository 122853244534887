// ProgressTracker.js

import React, { useEffect, useState } from "react";
import "./index.css";
import AppointmentCard from "../../components/common/appointmentCard";

const ScheduleAppointment = ({ data, activateNextClick }) => {
  return (
    <div className="pt-10 pb-5 px-5">
      <div>
        <div className="appointment container mx-auto pb-10 pt-3 w-full">
          <div className="calendar ">{/* <DateRangePickerComponent /> */}</div>
          {data &&
            data.length &&
            data.map((item, index) => {
              return (
                <div className="py-3 appoint-card">
                  <AppointmentCard
                    doctorData={item.providerDetail[0]}
                    providerId={item.providerId}
                    key={index}
                    activateNextClick={(appointmentId, appointmentSlotData) =>
                      activateNextClick(appointmentId, appointmentSlotData)
                    }
                  />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ScheduleAppointment;
