import React, { useEffect, useState, useContext } from "react";
import "./scheduler.scss";
import axios from "axios";
import FormRendererContext from "../../services/context/FormRendererContext";
import moment from "moment";
import Loader from "../../components/common/loader";

import SelectDropDown from "../../components/common/Select";
import OptionSelector from "../../components/common/OptionSelector";
import ScheduleAppointment from "../scheduleAppointment";
import TextField from "../../components/common/TextField";
import DateInput from "../../components/common/DateInput";
import Email from "../../components/common/Email";
import ModalBox from "../../components/common/modal";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  // bgcolor: 'background.paper hjdsgbfuhds kjdhiudh',
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const steps = ["Location", "Screening", "Schedule", "Your Info"];

const SchedulerWidget = ({
  widget,
  handleNextScreenPage = () => {},
  handleBackScreenPage = () => {},
  setAnswerForWidget = () => {},
}) => {
  const [schedulerId, setSchedulerId] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [reasons, setReasons] = useState([]);
  const [selectedReason, setSelectedReason] = useState(null);
  const [appointmentSlot, setAppointmentSlot] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [providerDetails, setProviderDetails] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const [firstName, setFirst] = useState("");
  const [lastName, setLast] = useState("");
  const [dob, setDateOfBirth] = useState(null);
  const [email, setEmail] = useState("");

  const {
    scheduler_id,
    setScheduler_id,
    provider_id,
    setProvider_id,
    reason_id,
    setReason_id,
    appointment_id,
    setAppointment_id,
    patient_id,
    setPatient_id,
    department_id,
    setDepartment_id,
    HBxFirstName,
    HBxLastName,
    HBxEmail,
    HBxDob,
    HBxReasonForVisit,
  } = useContext(FormRendererContext);

  const fetchDepartments = async (schedulerId) => {
    setIsLoading(true);
    let API_URL = `${
      process.env?.REACT_APP_SCHEDULER_URL ||
      "https://apps-sandbox.healthblox.care/scheduler"
    }/api/v1/providers/${schedulerId}/departments?providerlist=true`;
    try {
      const response = await axios.get(API_URL);
      const { HBx } = response?.data;
      const { departments } = HBx;
      for (let i = 0; i < departments.length; i++) {
        departments[i].value = departments[i].departmentId;
      }
      setDepartments(departments);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const fetchReasons = async () => {
    setIsLoading(true);
    let API_URL = `${
      process.env?.REACT_APP_SCHEDULER_URL ||
      "https://apps-sandbox.healthblox.care/scheduler"
    }/api/v1/appointments/${schedulerId}/reasons?departmentId=${selectedDepartment}&providerId=27`;
    try {
      const response = await axios.get(API_URL);
      const { HBx } = response?.data;
      const { patientappointmentreasons } = HBx;
      for (let i = 0; i < patientappointmentreasons.length; i++) {
        patientappointmentreasons[i].id = patientappointmentreasons[i].reasonid;
        patientappointmentreasons[i].label =
          patientappointmentreasons[i].reason;
      }
      setReasons(patientappointmentreasons);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const fetchProviderDetails = async (schedulerId, providerId) => {
    setIsLoading(true);
    let API_URL = `${
      process.env?.REACT_APP_SCHEDULER_URL ||
      "https://apps-sandbox.healthblox.care/scheduler"
    }/api/v1/providers/${schedulerId}/id/${providerId}`;
    try {
      const response = await axios.get(API_URL);
      const { HBx } = response?.data;
      setIsLoading(false);
      return HBx;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      return null;
    }
  };
  const handleOptionChange = (val) => {
    setSelectedDepartment(val);
    setDepartment_id(val);
  };

  const handleReasonChange = async (val) => {
    setIsLoading(true);
    setSelectedReason(val);
    setReason_id(val);
    let providersList = [];
    let selectedDepartmentData = departments.filter(
      (item) => item.departmentid === selectedDepartment
    )[0];
    let { providerlist } = selectedDepartmentData;

    // let providerlist = [
    //   "111",
    //   "27",
    //   "108",
    //   "109",
    //   "72",
    //   "87",
    //   "88",
    //   "67",
    // ];
    for (let i = 0; i < providerlist.length; i++) {
      let providerDetail = await fetchProviderDetails(
        schedulerId,
        providerlist[i]
      );
      providersList.push({ providerId: providerlist[i], providerDetail });
    }
    setProviderDetails(providersList);
    setCurrentStep((prev) => prev + 1);
    setIsLoading(false);
  };

  useEffect(() => {
    if (widget?.id) {
      setSchedulerId(widget.id);
      setScheduler_id(widget.id);
      fetchDepartments(widget.id);
    }
    if (HBxDob) {
      setDateOfBirth(HBxDob);
    }
    if (HBxEmail) {
      setEmail(HBxEmail);
    }
    if (HBxFirstName) {
      setFirst(HBxFirstName);
    }
    if (HBxLastName) {
      setLast(HBxLastName);
    }
  }, []);

  const handleBackClick = () => {
    if (currentStep === 0) {
      handleBackScreenPage();
    }
    if (currentStep === 1) {
      setCurrentStep((prev) => prev - 1);
    }
    if (currentStep === 2) {
      setCurrentStep((prev) => prev - 1);
    }
    if (currentStep === 3) {
      setCurrentStep((prev) => prev - 1);
    }
  };
  async function checkForUsers() {
    let API_URL = `${
      process.env?.REACT_APP_SCHEDULER_URL ||
      "https://apps-sandbox.healthblox.care/scheduler"
    }/api/v1/patients/${schedulerId}?firstName=${firstName}&lastName=${lastName}&DOB=${encodeURIComponent(
      dateFormatter(dob)
    )}&email=${encodeURIComponent(email)}`;
    try {
      const response = await axios.get(API_URL);
      const { HBx } = response.data;
      if (HBx && HBx?.patientId) return HBx.patientId;
      return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
  async function createAppointment(patientID) {
    setIsLoading(true);
    let API_URL = `${
      process.env?.REACT_APP_SCHEDULER_URL ||
      "https://apps-sandbox.healthblox.care/scheduler"
    }/api/v1/appointments/${schedulerId}`;
    try {
      const response = await axios.put(API_URL, {
        appointmentId: appointment_id,
        patientId: patientID,
        bookingNote: HBxReasonForVisit || "Health Check-up",
        departmentId: department_id,
        reasonId: reason_id,
      });
      const { HBx } = response.data;
      setAnswerForWidget(HBx);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }
  const handleNextClick = async () => {
    if (currentStep === 3) {
      if (firstName && lastName && dob && email) {
        setIsLoading(true);
        let patientID = await checkForUsers();
        if (patientID) {
          await createAppointment(patientID);
          setCurrentStep((prev) => prev + 1);
          setOpenModal(true);
          setPatient_id(patientID);
          setIsLoading(false);
        } else {
          let API_URL = `${
            process.env?.REACT_APP_SCHEDULER_URL ||
            "https://apps-sandbox.healthblox.care/scheduler"
          }/api/v1/patients/${schedulerId}`;
          try {
            const response = await axios.post(API_URL, {
              firstName: firstName,
              lastName: lastName,
              DOB: dateFormatter(dob),
              departmentId: department_id,
              email: [email],
            });
            const { HBx } = response?.data;
            const { patientId } = HBx;
            await createAppointment(patientId);
            setCurrentStep((prev) => prev + 1);
            setOpenModal(true);
            setPatient_id(patientId);
            setIsLoading(false);
          } catch (error) {
            console.log(error);
            setIsLoading(false);
          }
        }
      }
    }
    if (currentStep === 2) {
      setCurrentStep((prev) => prev + 1);
    }
    if (currentStep === 0) {
      if (selectedDepartment) {
        await fetchReasons();
        setCurrentStep((prev) => prev + 1);
      }
    }
  };
  function setFirstName(name, data) {
    setFirst(data.value);
  }
  function setLastName(name, data) {
    setLast(data.value);
  }
  function setDOB(name, data) {
    setDateOfBirth(data.value);
  }
  function dateFormatter(date) {
    const formattedDate = moment(date?.$d).format("MM/DD/YYYY");
    return formattedDate;
  }
  function setEmailId(name, data) {
    setEmail(data.value);
  }
  return (
    <div className="">
      {!isLoading ? (
        <>
          {/* <div
            className={
              currentStep === 2
                ? "content-width p-5 hidden"
                : "content-width p-5"
            }
          >
            <div className="form-width mx-auto">
              <div
                className="progress-tracker py-10"
                style={{ maxWidth: "400px", margin: "auto" }}
              >
                <Box sx={{ width: "100%" }}>
                  <Stepper activeStep={currentStep} alternativeLabel>
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
              </div>
            </div>
          </div> */}
          {currentStep === 0 && (
            <div className="content-width p-5">
              <div className="w-full form-width mx-auto">
                <div className="form-title">What state are you located in?</div>
                <div className="radio-common my-2">
                  <SelectDropDown
                    placeHolder="Please select an option"
                    options={departments}
                    setOption={handleOptionChange}
                    answerValue={selectedDepartment}
                  />
                </div>
              </div>
            </div>
          )}
          {currentStep === 1 && (
            <div className="content-width p-5">
              <div className="form-width mx-auto">
                <div className="form-title">Choose the reason for visit</div>
                <OptionSelector
                  data={{ values: reasons }}
                  setOption={handleReasonChange}
                />
              </div>
            </div>
          )}
          {currentStep === 2 && (
            <div>
              <ScheduleAppointment
                data={providerDetails}
                activateNextClick={handleNextClick}
              />
            </div>
          )}

          {currentStep === 3 && (
            <div>
              <div className="content-width p-5">
                <div className="form-width mx-auto">
                  <TextField
                    data={{ label: "First Name", answer: { value: firstName } }}
                    setAnswer={setFirstName}
                  />
                  <TextField
                    data={{ label: "Last Name", answer: { value: lastName } }}
                    setAnswer={setLastName}
                  />
                  <DateInput
                    data={{ label: "DOB", answer: { value: dob } }}
                    setAnswer={setDOB}
                  />
                  <Email
                    data={{ label: "Email", answer: { value: email } }}
                    setAnswer={setEmailId}
                  />
                </div>
              </div>
            </div>
          )}
          {currentStep === 4 && openModal && (
            <>
              <ModalBox
                openModal={true}
                leftButtonText={"Close"}
                rightButtonText={"Continue"}
                rightButtonCallback={() => {
                  setOpenModal(false);
                  handleNextScreenPage();
                }}
                title={"Thank You !"}
                SubTitle={"Your Appointment has been Scheduled successfully"}
                hideClose={true}
              />
              {/* <Modal
                open={true}
                onClose={() => {}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} className="rounded bg-zinc-300">
                  <div className="text-center">
                    <TfiCheckBox
                      size={52}
                      className="my-auto ml-1 p-1 inline-block"
                    />
                  </div>
                  <div className="font-semibold text-2xl py-1 text-center">
                    Thank You !
                  </div>
                  <div className="text-xl py-1 text-center">
                    Your Appointment has been Scheduled successfully
                  </div>
                  <div className="flex justify-center">
                    <Button
                      onClick={() => {
                        handleNextScreenPage();
                      }}
                      sx={{
                        backgroundColor: "#6366f1",
                        "&:hover": {
                          backgroundColor: "#7c7ff0",
                        },
                        color: "white",
                      }}
                      className="rounded-xl text-white p-2 shadow p-2 bg-indigo-500 text-white px-3 cursor-pointer hover:bg-red-600 "
                    >
                      Continue
                    </Button>
                  </div>
                </Box>
              </Modal> */}
            </>
          )}
        </>
      ) : (
        <div className="mt-20">
          <Loader />
        </div>
      )}
      <div className="bottom-bar-shadow bottom-bar-scheduler-shadow">
        <div className="flex justify-between pt-3 m-auto bottom-bar-scheduler w-full">
          <button onClick={handleBackClick} className="text_button">
            Back
          </button>
          <div
            className={currentStep === 1 || currentStep === 2 ? "hidden" : ""}
          >
            <div onClick={handleNextClick} className="primary_button">
              {currentStep === 3 ? "Schedule Appointment" : "Continue"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SchedulerWidget;
