import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

// Styled Tooltip component with custom height and max-width
const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ width }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: width || 400,       // Set a max width or make it customizable
    overflowY: 'auto',    // Allow vertical scrolling if content exceeds max height
    backgroundColor: '#f5f5f9',
    color: 'rgb(107 114 128)',
    fontSize: '0.80rem',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
  },
}));

// CustomTooltip component with tooltipText, height, and iconSize props
const CustomTooltip = ({ tooltipText, width, iconSize = 15 }) => {
  return (
    <StyledTooltip title={tooltipText} width={width} arrow>
      <InfoIcon sx={{ fontSize: `${iconSize}px`, color: 'rgba(0, 0, 0, 0.38)', cursor: 'pointer' }} />
    </StyledTooltip>
  );
};


export default CustomTooltip;
