import axios from "axios";

const token =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY2ZDliMTdlOTNmNjQ1OTM4MThjOGRjOSIsImZpcnN0bmFtZSI6IkRlbW8iLCJsYXN0bmFtZSI6IkFQSSIsInJvbGVzIjpbInNlcnZpY2UiXSwib3JnSWQiOiI2Njk2NTI0OTFjOWNiYjQwYjBjMTNlNWMiLCJvcmdOYW1lIjoiSEJ4IERlbW8iLCJzY29wZSI6IioiLCJpYXQiOjE3MjYwNDk4MTUsImV4cCI6MTcyODY0MTgxNX0.caYwtbv6u-nA0bqaXJdy13Y1wA4qpJjRbCT6OlMuZOw";
const headers = {
  headers: {
    // "x-auth-token": token,
  },
};

export const callAthenaGetApi = async (API_URL, body = {}) => {
  try {
    // console.log(body);
    const response = await axios.get(API_URL, body, headers);
    return response;
  } catch (e) {
    console.log(e);
    return { error: true };
  }
};
export const callAthenaPostApi = async (API_URL, body = {}) => {
  try {
    // console.log(body);
    const response = await axios.post(API_URL, body, headers);
    return response;
  } catch (e) {
    console.log(e);
    return { error: true };
  }
};
export const callAthenaPutApi = async (API_URL, body = {}) => {
  try {
    // console.log(body);
    const response = await axios.put(API_URL, body, headers);
    return response;
  } catch (e) {
    console.log(e);
    return { error: true };
  }
};
