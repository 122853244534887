import { useState, useEffect, useRef, useContext } from "react";
import "./common.scss";
import Loader from "./loader";
import FormRendererContext from "../../services/context/FormRendererContext";
import { callAthenaGetApi } from "../../utils/athena";

const DropDownWithSearch = ({
  data,
  setAnswer,
  formValidation = true,
  formClass,
}) => {
  const [selectedOption, setSelectedOption] = useState(
    data.answer?.value || ""
  );
  const [validForm, setFormValidation] = useState(formValidation);

  const [showDropdown, setShowDropdown] = useState(false); // Manage dropdown visibility
  const [options, setOptions] = useState([]); // Store fetched options
  const [query, setQuery] = useState(""); // Store user input for search
  const [loading, setLoading] = useState(false); // Loading state for API call
  const dropdownRef = useRef(null); // Ref to track dropdown for click outside detection

  const { answerData, hashIdFromQP } = useContext(FormRendererContext);

  const throttle = (func, limit) => {
    let inThrottle;
    return function (...args) {
      if (!inThrottle) {
        func(...args);
        inThrottle = true;
        setTimeout(() => (inThrottle = false), limit);
      }
    };
  };

  // Function to fetch options from the API
  const fetchOptions = async (query) => {
    if (query.length > 2) {
    const API_URL = process.env.REACT_APP_SCHEDULER_URL;
    const fetchClinicalProvidersUrl = `${API_URL}/api/v1/checkin/${hashIdFromQP}/configuration/clinicalprovider?name=${query}&ZIP=${parseInt(answerData?.demographicsZipCode?.value, 10) || "94085"}`;
      const response = await callAthenaGetApi(fetchClinicalProvidersUrl);
      return response.data?.HBx || []
    }
  };

  // Throttled API call for fetching options
  const fetchThrottledOptions = throttle((query) => {
    if (query.length > 2) {
      setLoading(true);
      fetchOptions(query).then((result) => {
        setOptions(result); // Update options
        setLoading(false);
      });
    } else {
      setOptions([]); // Clear options when input is empty
    }
  }, 500); // Throttle with a delay of 500ms


  // Handle click outside to close the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handle selecting an option
  const handleOptionSelect = (option) => {
    setSelectedOption(option?.name);
    setQuery(option?.name)
    setAnswer(data, { value: option });
    setFormValidation(true);
    setShowDropdown(false); // Hide dropdown after selecting an option
  };

  useEffect(() => {
    setFormValidation(formValidation);
    if (data?.answer) {
      setSelectedOption(data.answer?.value?.name);
      setQuery(data.answer?.value?.name)
    } else {
      setQuery("")
      setSelectedOption("")
    } 
  }, [data]);

  return (
    <div className={`w-full ${formClass}`} ref={dropdownRef}>
      <div className="form-title pt-3">
        {data?.label}
        {data?.validate?.required ? (
          <span className={!formValidation ? "text-red-700" : "text-gray-600"}>
            *
          </span>
        ) : (
          <></>
        )}
      </div>
      <form className="flex-grow">
        <div className="radio-common relative">
          <label className="relative">
            <input
              type="text"
              disabled={data?.disabled || false}
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
                fetchThrottledOptions(e.target.value) 
                setShowDropdown(true);
              }}
              placeholder={data?.suffix ? "" : data?.placeholder}
              className={
                data?.suffix
                  ? "text-field text-input text_white"
                  : "text-field text-input"
              }
            />
            {data?.suffix && (
              <div
                className="editable_div absolute w-full"
                onClick={() => {
                  var div = document.getElementById(data?.label + "editable");
                  div.focus();
                }}
              >
                <div
                  contenteditable="false"
                  className="editable_span inline-block pr-1"
                  id={data?.label + "editable"}
                >
                  {selectedOption}
                </div>
                <div
                  contenteditable="false"
                  className="non_editable_span inline-block"
                >
                  {data.suffix}
                </div>
              </div>
            )}
            {/* Dropdown list of options */}
            {showDropdown && (
              <div
                className="absolute w-full border rounded-lg mt-3 bg-white overflow-y-auto z-10"
                style={{ maxHeight: "260px", minHeight: "64px" , borderColor: "#dcdfe4"}} // Set min and max height
              >
                {loading ? (
                  <div className="text-center py-2">
                    <Loader /> {/* Show loader while fetching data */}
                  </div>
                ) : (
                  options.map((option, index) => (
                    <div
                      key={index}
                      className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                      onClick={() => handleOptionSelect(option)}
                    >
                      {option?.name}
                    </div>
                  ))
                )}
                {options.length === 0 && !loading && (
                  <div className="text-center py-2 text-gray-500">
                    No options found
                  </div>
                )}
              </div>
            )}
          </label>
        </div>
      </form>
      {data?.validate?.required && !formValidation ? (
        <span className="text-red-700 font-light text-xs">
          {data?.invalidMessage || "Required Field"}
        </span>
      ) : (
        <></>
      )}
    </div>
  );
};
export default DropDownWithSearch;
