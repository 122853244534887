import { useState, useEffect } from "react";
import "./common.scss";

const Password = ({ data, setAnswer, formValidation, formClass }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [validForm, setFormValidation] = useState(formValidation);

  useEffect(() => {
    setFormValidation(formValidation);
    if (data?.answer) {
      setSelectedOption(data.answer?.value);
    }
  }, []);

  return (
    <div className={`w-full ${formClass}`}>
      <div className="form-title pt-3">
        {data.label}
        {data?.validate?.required ? (
          <span className={!formValidation ? "text-red-700" : "text-gray-600"}>
            {" "}
            *
          </span>
        ) : (
          <></>
        )}
      </div>
      <form className="flex-grow">
        <div className="radio-common">
          <input
            type="password"
            value={selectedOption}
            onChange={(e) => {
              setSelectedOption(e.target.value);
              setAnswer(data, { value: e.target.value });
              setFormValidation(true);
            }}
            className="text-field text-input"
            placeholder={data?.placeholder}
            disabled={data?.disabled || false}
            />
        </div>
      </form>
      {data?.validate?.required && !formValidation ? (
        <span className="text-red-700 font-light text-xs">
          {data?.invalidMessage || "Required Field"}
        </span>
      ) : (
        <></>
      )}
    </div>
  );
};
export default Password;
