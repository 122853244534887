import { useState, useEffect } from "react";
import "./common.scss";
import SignaturePad from "react-signature-canvas";

const Signature = ({ data, setAnswer, formValidation }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [validForm, setFormValidation] = useState(formValidation);
  const [sigPad, setSigPad] = useState({});
  function handleEnd() {
    let x = sigPad.getTrimmedCanvas().toDataURL("image/png");

  }

  useEffect(() => {
    setFormValidation(formValidation);
    if (data?.answer) {
      setSelectedOption(data.answer?.value);
    }
  }, [formValidation]);

  return (
    <div className=" w-full">
      <div className="form-title pt-3">{data.label}</div>

      {data?.validate?.required ? (
        <span className={!validForm ? "text-red-700" : ""}> * </span>
      ) : (
        <></>
      )}
      {data?.validate?.required && !validForm ? (
        <span className="text-red-700 font-light text-xs">
          {" "}
          Required Field{" "}
        </span>
      ) : (
        <></>
      )}
      <form>
        <div className="radio-common my-2">
          {/* <label>
            <input
              type="text"
              value={selectedOption}
              onChange={(e) => {
                setSelectedOption(e.target.value);
                setAnswer(data, {value: e.target.value});
                setFormValidation(true);
              }}
              className="text-field text-input"
            />
          </label> */}
          <div>
            <SignaturePad
                          className="text-field text-input"
              ref={(ref) => {
                setSigPad(ref);
              }}
              onEnd={handleEnd}
            />
          </div>
        </div>
      </form>
    </div>
  );
};
export default Signature;
