import RoutesPage from "./Routes";
import FormRendererContextProvider from "./services/context/FormRendererContextProvider";
import CssBaseline from "@mui/material/CssBaseline";
import ThemeSelectorContextProvider from "./services/context/ThemeSelectorContextProvider";
import ScrollToTop from "./utils/scrollTop";

function App() {
  return (
    <ThemeSelectorContextProvider>
    <FormRendererContextProvider>
        <CssBaseline />
        <div className="app">
          <ScrollToTop />
          <RoutesPage />
        </div>
    </FormRendererContextProvider>
    </ThemeSelectorContextProvider>
  );
}

export default App;
