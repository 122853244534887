import { useState, useEffect, useRef, useContext } from "react";
import "./common.scss";
import Loader from "./loader";
import FormRendererContext from "../../services/context/FormRendererContext";
import { callAthenaGetApi } from "../../utils/athena";
import DropDownWithSearchForAllergy from "./DropDownWithSearchForAllergy";
import DateInput from "./DateInput";
import DropDownSelector from "./DropDownSelector";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBoxIcon from "@mui/icons-material/AddBox";
import NumberInput from "./Number";

const HealthHistoryMedicationComponent = ({
  data,
  setAnswer,
  formValidation = true,
  formClass,
}) => {
  const [healthHistoryFormId, setHealthHistoryFormId] = useState(5793);

  const [medications, setMedications] = useState(null);
  const [medicationsForm, setMedicationsForm] = useState([]);
  const [isLoading, setIsloading] = useState(true);

  const { answerData, hashIdFromQP, medicationData, setMedicationData } =
    useContext(FormRendererContext);

  useEffect(() => {
    if (medicationData) {
      setMedications(medicationData);
      setIsloading(false);
    } else updateHealthHistoryFormId();
  }, [data]);

  const updateHealthHistoryFormId = async () => {
    const API_URL = process.env.REACT_APP_SCHEDULER_URL;
    const getHealthHistoryApiUrl = data?.getHealthHistoryApiUrl;

    const API_COMPLETE_URL = getHealthHistoryApiUrl
      .replace("${API_URL}", API_URL)
      .replace("${hashIdFromQP}", hashIdFromQP);
    const res = await callAthenaGetApi(API_COMPLETE_URL);

    const { HBx } = res.data;

    const justMedicationsForm = HBx.find(
      (form) => form.formname === "Medication Only"
    );
    const formId = justMedicationsForm ? justMedicationsForm.formid : null;
    if (formId) {
      setHealthHistoryFormId(formId);
      updateHealthHistoryMedication(formId);
    }
    return formId;
  };

  const generateUniqueKey = () =>
    Date.now().toString(36) + Math.random().toString(36).substring(2);

  const updateHealthHistoryMedication = async (formId) => {
    const API_URL = process.env.REACT_APP_SCHEDULER_URL;
    const getHealthHistoryMedicationApiUrl =
      data?.getHealthHistoryMedicationApiUrl;

    const API_COMPLETE_URL = getHealthHistoryMedicationApiUrl
      .replace("${API_URL}", API_URL)
      .replace("${hashIdFromQP}", hashIdFromQP)
      .replace("${healthHistoryFormId}", formId);
    const res = await callAthenaGetApi(API_COMPLETE_URL);

    const { HBx } = res.data;
    let newMedicationsForm = [];

    HBx.forEach((item) => {
      const { fielddata } = item;
      let formattedQuesObj = null;
      for (let key in fielddata) {
        formattedQuesObj = null;
        if (fielddata.hasOwnProperty(key)) {
          // Ensures the key is a property of fielddata and not inherited
          let quesObj = fielddata[key];

          if (quesObj?.inputtype === "NUMERIC" && key === "dosagequantity") {
            formattedQuesObj = {
              ...quesObj,
              label: "Please enter medication dose(MG)",
              key: key,
              subKey: key,
              type: "number",
              selectedOption: "",
              answer: {
                value: "",
              },
            };
          }

          if (quesObj?.inputtype === "DROPDOWN" && key === "frequency") {
            formattedQuesObj = {
              ...quesObj,
              label: "Specify how often you take the medication",
              key: key,
              subKey: key,
              type: "select",
              data: {
                values: [],
              },
              selectedOption: "",
              answer: {
                value: "",
              },
            };

            quesObj?.dropdownvalues?.forEach((option) => {
              formattedQuesObj.data.values.push({
                label: option[1],
                value: option[0],
              });
            });
          }

          if (
            quesObj?.inputtype === "DROPDOWN" &&
            key === "meddeactivatereasonid"
          ) {
            formattedQuesObj = {
              ...quesObj,
              label:
                "Please provide the reason for discontinuing your medication",
              key: key,
              subKey: key,
              type: "select",
              data: {
                values: [],
              },
              selectedOption: "",
              answer: {
                value: "",
              },
            };

            quesObj?.dropdownvalues?.forEach((option) => {
              formattedQuesObj.data.values.push({
                label: option[1],
                value: option[0],
              });
            });
          }
          if (quesObj?.inputtype === "DATE" && key === "startdate") {
            formattedQuesObj = {
              ...quesObj,
              label: "Medication Start Date",
              key: key,
              subKey: key,
              maxDate: "today",
              type: "datetime",
              selectedOption: "",
              answer: {
                value: "",
              },
            };
          }
          if (quesObj?.inputtype === "DATE" && key === "stopdate") {
            formattedQuesObj = {
              ...quesObj,
              label: "Medication End Date",
              maxDate: "today",
              key: key,
              subKey: key,
              type: "datetime",
              selectedOption: "",
              answer: {
                value: "",
              },
            };
          }
        }
        formattedQuesObj && newMedicationsForm.push(formattedQuesObj);
      }
    });
    newMedicationsForm.forEach((item, index) => {
      // Update the key with a new format; here we're appending `_new` for demonstration
      item.key = `${item.key}_${generateUniqueKey()}`;
    });
    setMedicationsForm(newMedicationsForm);
    setMedications([
      {
        components: [
          {
            label: "Please enter the name of your medication",
            key: generateUniqueKey(),
            subKey: "searchMedication",
            type: "searchMedication",
            apiForSearch: data?.getMedicationApiUrl,
            selectedOption: "",
            answer: {
              value: "",
            },
          },
          ...structuredClone(newMedicationsForm),
        ],
      },
    ]);
    setIsloading(false);
  };

  const fetchOptions = async (query) => {
    const API_URL = process.env.REACT_APP_SCHEDULER_URL;
    const getMedicationApiUrl = data?.getMedicationApiUrl;

    const API_COMPLETE_URL = getMedicationApiUrl
      .replace("${API_URL}", API_URL)
      .replace("${hashIdFromQP}", hashIdFromQP)
      .replace("${searchParam}", query);

    try {
      const res = await callAthenaGetApi(API_COMPLETE_URL);
      const { HBx } = res.data;
      return HBx || []; // Make sure it returns an array of options
    } catch (error) {
      console.error("Error fetching options:", error);
      return [];
    }
  };

  // Add a new medication entry
  const handleAddMedication = () => {
    const newMedicationsFormWithNewKey = structuredClone(medicationsForm);
    newMedicationsFormWithNewKey.forEach((item, index) => {
      // Update the key with a new format; here we're appending `_new` for demonstration
      item.key = `${item.key}_${generateUniqueKey()}`;
    });
    setMedications([
      ...medications,
      {
        components: [
          {
            label: "Please enter the name of your medication",
            key: generateUniqueKey(),
            subKey: "searchMedication",
            type: "searchMedication",
            apiForSearch: data?.getMedicationApiUrl,
            selectedOption: "",
            answer: {
              value: "",
            },
          },
          ...newMedicationsFormWithNewKey,
        ],
      },
    ]);
  };

  // Remove an medication entry
  const handleRemoveMedication = (index) => {
    if (medications?.length > 1)
      setMedications(medications.filter((_, i) => i !== index));
  };

  const handleUpdateAnswer = (index, componentIndex, value, label) => {
    let updatedMedications = [...medications];
    updatedMedications[index].components[componentIndex].selectedOption = value;
    updatedMedications[index].components[componentIndex].answer.value = value;
    if (label) {
      updatedMedications[index].components[componentIndex].answer.label = label;
    }
    setMedicationData(updatedMedications);
  };

  return (
    <>
      <div className={`w-full `}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="space-y-4 sm:space-y-6 md:space-y-8 lg:space-y-10">
              {!!medications &&
                medications?.length &&
                medications?.map((medication, index) => {
                  return (
                    <div className="p-4 bg-white rounded-md sm:p-4 md:p-6 lg:p-8 shadow-md border border-gray-200">
                      <div className="form-width mx-auto flex justify-end my-1 cursor-pointer">
                        <div onClick={() => handleRemoveMedication(index)}>
                          <DeleteIcon />
                        </div>
                      </div>
                      {medication &&
                        medication?.components &&
                        medication.components?.map((item, componentIndex) => {
                          return (
                            <>
                              {item?.type === "datetime" && (
                                <div className="form-width mx-auto flex justify-center my-3">
                                  <DateInput
                                    data={item}
                                    setAnswer={(data, { value, label }) => {
                                      handleUpdateAnswer(
                                        index,
                                        componentIndex,
                                        value
                                      );
                                      setAnswer(data, { value, label });
                                    }}
                                    formClass={formClass}
                                    formValidation={formValidation}
                                  />
                                </div>
                              )}

                              {item?.type === "number" && (
                                <div className="form-width mx-auto flex justify-center my-3">
                                  <NumberInput
                                    data={item}
                                    setAnswer={(data, { value }) => {
                                      handleUpdateAnswer(
                                        index,
                                        componentIndex,
                                        value
                                      );
                                      setAnswer(data, { value });
                                    }}
                                    formClass={formClass}
                                    formValidation={formValidation}
                                  />
                                </div>
                              )}

                              {item?.type === "select" && (
                                <div className="form-width mx-auto flex justify-center my-3">
                                  <DropDownSelector
                                    data={item}
                                    setAnswer={(data, { value, label }) => {
                                      handleUpdateAnswer(
                                        index,
                                        componentIndex,
                                        value
                                      );
                                      setAnswer(data, { value, label });
                                    }}
                                  />
                                </div>
                              )}

                              {item?.type === "searchMedication" && (
                                <div className="form-width mx-auto flex justify-center my-3">
                                  <DropDownWithSearchForAllergy
                                    label={item?.label}
                                    fetchOptions={fetchOptions}
                                    selectedOptionData={{
                                      label: item?.answer?.label || "",
                                    }}
                                    onOptionSelect={(option) => {
                                      handleUpdateAnswer(
                                        index,
                                        componentIndex,
                                        option?.medicationid,
                                        option?.medication
                                      );
                                      setAnswer(item, {
                                        value: option?.medicationid,
                                        label: option?.medication,
                                      });
                                    }}
                                    //   onOptionSelect={handleOptionSelect}
                                    displayKey={"medication"}
                                  />
                                </div>
                              )}
                            </>
                          );
                        })}
                    </div>
                  );
                })}
            </div>
            <div className="form-width mx-auto flex justify-end my-1 cursor-pointer">
              <div onClick={handleAddMedication} className="px-3 pt-3">
                <AddBoxIcon sx={{ fontSize: "1.7rem" }} />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default HealthHistoryMedicationComponent;
